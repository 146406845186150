import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { Menu } from '@/bundles/model';
import { IS_PRODUCTION_MODE } from '@/config';
import { ExtractState, Params } from '@/types';

interface MenuStoreState {
  menus: Menu[];
  actions: {
    updateMenus: (menus: Menu[]) => void;
  };
}

const menuStore = createStore<MenuStoreState>()(
  devtools(
    set => ({
      menus: [],
      actions: {
        updateMenus: (menus: Menu[]) => {
          set({
            menus,
          });
        },
      },
    }),
    {
      name: 'menus',
      enable: !IS_PRODUCTION_MODE,
    },
  ),
);

const useMenuStore = <U>(
  selector: Params<U, typeof menuStore>[1],
  equalityFn?: Params<U, typeof menuStore>[2],
) => {
  return useStoreWithEqualityFn(menuStore, selector, equalityFn);
};

// Selectors
const menusSelector = (state: ExtractState<typeof menuStore>) => state.menus;

const menusActionsSelector = (state: ExtractState<typeof menuStore>) =>
  state.actions;

// Getters
const getMenus = () => menusSelector(menuStore.getState());

const getMenusAction = () => menusActionsSelector(menuStore.getState());

// Hooks
const useMenu = () => useMenuStore(menusSelector, shallow);

export {
  getMenus,
  // Getters
  getMenusAction,
  // Hooks
  useMenu,
};
