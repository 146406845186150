import {
  Divider,
  Grid,
  InputLabel,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

const useStyles = makeStyles(() =>
  createStyles({
    inputLabelText: {
      width: '100%',
      flexWrap: 'nowrap',
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 'unset',
      },
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: 'unset',
      },
      '& .MuiIconButton-root': {
        padding: '4px',
        marginRight: 'unset',
      },
      '& .MuiFormHelperText-root': {
        marginRight: 'unset',
        marginLeft: 'unset',
      },
      '&__label': {
        color: '#000000',
        display: 'flex',
        flexDirection: 'row',
        '&--left': {
          paddingTop: '4px',
          marginRight: '4px',
        },
        '&--required': {
          '& .MuiFormLabel-asterisk': {
            color: 'red',
          },
        },
        '&--bold': {
          fontWeight: 'bold',
        },
      },
    },
    labelValue: {
      height: 32,
      paddingLeft: 14,
      paddingRight: 14,
      display: 'flex',
      alignItems: 'center',
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        color: '#000000',
      },
    },
  }),
);

type Position = 'left' | 'top';

type Props = {
  /**
   * Label display text.
   */
  label?: string;
  /**
   * Label value display text.
   */
  value?: string;
  /**
   * Position of label to use: none | left | top
   */
  labelPosition?: Position;
  /**
   * Add a divider below the text value
   */
  hasDivider?: boolean;
  /**
   * Registration form props for react-hook-form.
   */
  registrationForm?: Partial<UseFormRegisterReturn>;
};

export type InputLabelTextProps = Props & TextFieldProps;

export const InputLabelText: React.FC<InputLabelTextProps> = ({
  label,
  value = '-',
  labelPosition,
  hasDivider = false,
  ...restProps
}: InputLabelTextProps) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      direction={labelPosition === 'top' ? 'column' : 'row'}
      className={classes.inputLabelText}
    >
      <Grid item>
        <InputLabel
          htmlFor={restProps.id}
          className={clsx(`${classes.inputLabelText}__label`, {
            [`${classes.inputLabelText}__label--left`]:
              labelPosition === 'left',
            [`${classes.inputLabelText}__label--required`]:
              !!restProps.required,
            [`${classes.inputLabelText}__label--bold`]:
              restProps.size == 'medium',
          })}
          required={restProps.required}
        >
          {label}
        </InputLabel>
      </Grid>
      <Grid item className={classes.labelValue}>
        <Typography
          variant="body2"
          sx={{
            fontSize: 16,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {value}
        </Typography>
      </Grid>
      {hasDivider && <Divider style={{ marginTop: -4 }} />}
    </Grid>
  );
};

InputLabelText.defaultProps = {
  labelPosition: 'top',
};
