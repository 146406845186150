import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
    },
    header: {
      padding: theme.spacing(1),
      backgroundColor: 'rgba(255, 255, 255, 0.45)',
      '&__title': {
        padding: '0 8px',
      },
    },
    content: {
      marginTop: '16px',
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(1),
    },
  }),
);

/**
 * Props for ContentLayout component.
 */
type ContentLayoutProps = {
  /**
   * The title of the layout.
   */
  title: React.ReactNode | string;
  /**
   * The element inside which wrapped by the content layout.
   */
  children: React.ReactNode;
};

export const ContentLayout: React.FC<ContentLayoutProps> = ({
  title,
  children,
}: ContentLayoutProps) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Grid item className={classes.header}>
        {typeof title === 'string' ? (
          <Typography variant="h1" align="left" color="secondary">
            {title}
          </Typography>
        ) : (
          <Grid item container className={`${classes.header}__title`}>
            {title}
          </Grid>
        )}
      </Grid>
      <Grid item className={classes.content}>
        {children}
      </Grid>
    </Grid>
  );
};
