import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  Badge,
  Collapse,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import MaterialFontIcon from '../Icons/MaterialFontIcon';

import { SELECTED_ITEM_BACKGROUND } from './constants';
import { CaseBadgeContent } from './types';

import { Menu } from '@/bundles/model';
import { Authorization } from '@/features/authorization';
import { useFetchNumberOfCases } from '@/features/cases/hooks/useFetchNumberOfCases';
import { routes } from '@/routes';
import { resetState } from '@/utils/resetState';

const useStyles = makeStyles(() =>
  createStyles({
    menuItem: {
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  }),
);

const ItemButton = withStyles({
  root: {
    borderRadius: '8px',
    '&& svg': {
      color: '#000000',
    },
    '&:hover': {
      backgroundColor: SELECTED_ITEM_BACKGROUND,
      color: '#FFFFFF',
      '&& svg': {
        color: '#FFFFFF',
      },
    },
  },
})(ListItemButton);

const ItemIcon = withStyles({
  root: {
    '&.active': {
      '&& svg': {
        color: '#FFFFFF',
      },
    },
  },
})(ListItemIcon);

const CollapsedItemButton = withStyles({
  root: {
    paddingRight: '16px',
    '&& svg': {
      color: '#000000',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: SELECTED_ITEM_BACKGROUND,
      '&& svg': {
        color: SELECTED_ITEM_BACKGROUND,
      },
    },
  },
})(ListItemButton);

const CollapsedItemIcon = withStyles({
  root: {
    '&.active': {
      '&& svg': {
        color: ` ${SELECTED_ITEM_BACKGROUND}`,
      },
    },
  },
})(ListItemIcon);

enum CasesFeatureRoutes {
  All = '/cases/all',
  ApprovalPending = '/cases/approval-pending',
  Temporary = '/cases/editing',
}

type MenuItemProps = Menu & {
  open: boolean;
  activeItem: string;
  updateActiveItem: React.Dispatch<React.SetStateAction<string>>;
};

const MenuItem: React.FC<MenuItemProps> = ({
  children,
  icon,
  path,
  title: name,
  open,
  access: accessEntity,
  updateActiveItem,
  activeItem,
}: MenuItemProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const isActive =
    path === activeItem ||
    children?.some(({ path }) => path === activeItem) ||
    false;

  const [openCollapseMenu, setOpenCollapseMenu] = useState<boolean>(isActive);
  const [apiCallTimer, setApiCallTimer] = useState<NodeJS.Timeout | null>(null);
  const [caseBadgeContent, setCaseBadgeContent] = useState<CaseBadgeContent>({
    all: 0,
    approvalPending: 0,
    temporary: 0,
  });
  const { fetchNumberOfCase } = useFetchNumberOfCases();

  const caseBadgeContentMap: Record<string, number> = {
    [CasesFeatureRoutes.ApprovalPending]: caseBadgeContent.approvalPending,
    [CasesFeatureRoutes.Temporary]: caseBadgeContent.temporary,
    [CasesFeatureRoutes.All]: caseBadgeContent.all,
  };

  const isCaseManagementMatched = (children: Menu[]) =>
    children?.some(child => child.path?.includes('/cases'));

  const apiGetNumber = async () => {
    const result = await fetchNumberOfCase();
    setCaseBadgeContent(result);
  };

  useEffect(() => {
    const fetchNumberOfCases = async () => {
      if (!children) return;
      const matched = isCaseManagementMatched(children);
      if (open && matched && openCollapseMenu) {
        apiGetNumber();
        const timer = setInterval(apiGetNumber, 15000);
        setApiCallTimer(timer);
      } else {
        if (apiCallTimer) {
          clearInterval(apiCallTimer);
          setApiCallTimer(null);
        }
      }
    };

    fetchNumberOfCases();

    return () => {
      if (apiCallTimer) {
        clearInterval(apiCallTimer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, children, openCollapseMenu]);

  const handleNavigate = (route?: string) => {
    if (route) {
      navigate(route);
      updateActiveItem(route);
    }
  };

  const selectedText = isActive ? '#FFFFFF' : undefined;

  const renderCollapsedIcon = () =>
    children &&
    (openCollapseMenu ? (
      <KeyboardArrowDownIcon style={{ color: selectedText }} />
    ) : (
      <KeyboardArrowLeftIcon style={{ color: selectedText }} />
    ));

  const renderChildItem = () => {
    if (!children) return null;

    return (
      <Collapse in={openCollapseMenu} timeout="auto" unmountOnExit>
        {children.map(
          ({
            title: name,
            icon,
            path: childPath,
            access: childAccessEntity,
          }) => {
            const isChildActive = childPath === activeItem;

            //* The badge hides automatically when `badgeContent` is zero
            let badgeContent = 0;
            if (childPath?.includes(routes.cases.index)) {
              badgeContent = caseBadgeContentMap[childPath];
            }

            return (
              <Authorization key={name} accessEntity={childAccessEntity ?? ''}>
                <ListItem disablePadding key={name}>
                  <CollapsedItemButton
                    sx={{ pl: 4 }}
                    onClick={() => { 
                      resetState(childPath);
                      handleNavigate(childPath)
                    }}
                  >
                    <CollapsedItemIcon
                      className={isChildActive ? 'active' : undefined}
                    >
                      {icon && <MaterialFontIcon icon={icon} />}
                    </CollapsedItemIcon>
                    <Badge
                      badgeContent={badgeContent}
                      max={99}
                      color={
                        childPath === '/cases/approval-pending'
                          ? 'error'
                          : 'primary'
                      }
                      slotProps={{
                        badge: {
                          style: {
                            top: '8px',
                            right: '-16px',
                          },
                        },
                      }}
                    >
                      <ListItemText
                        style={{
                          width: '100%',
                          color: isChildActive
                            ? SELECTED_ITEM_BACKGROUND
                            : undefined,
                        }}
                        primary={t(name)}
                      />
                    </Badge>
                  </CollapsedItemButton>
                </ListItem>
              </Authorization>
            );
          },
        )}
      </Collapse>
    );
  };

  return (
    <Authorization accessEntity={accessEntity ?? ''}>
      <Grid item container direction="column" className={classes.menuItem}>
        <ListItem disablePadding>
          <ItemButton
            style={{
              backgroundColor: isActive ? SELECTED_ITEM_BACKGROUND : undefined,
            }}
            onClick={() => {
              if(children) {
                setOpenCollapseMenu(prevState => !prevState)
                return;
              }
              resetState(path);
              handleNavigate(path);
            }}
          >
            <ItemIcon className={isActive ? 'active' : undefined}>
              {icon && <MaterialFontIcon icon={icon} />}
            </ItemIcon>
            <ListItemText style={{ color: selectedText }} primary={t(name)} />
            {renderCollapsedIcon()}
          </ItemButton>
        </ListItem>
        {renderChildItem()}
      </Grid>
    </Authorization>
  );
};

export default MenuItem;
