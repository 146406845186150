import type { AxiosRequestConfig } from 'axios';

import { GetCaseResponse, GetcaseApi } from '@/bundles/model';
import { ApiConfiguration } from '@/lib/swagger';

const getCaseApi = new GetcaseApi(new ApiConfiguration().configuration);

export const getCase = async (
  id?: number,
  options?: AxiosRequestConfig,
): Promise<GetCaseResponse> => {
  if (!id || isNaN(id)) {
    throw new Error();
  }

  const response = await getCaseApi.getCase(id, options);
  if (response.status !== 200) {
    throw new Error('getCase failed');
  }

  return response.data;
};
