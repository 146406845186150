import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { StoresSearchQuery } from '../types';

import { Store } from '@/bundles/model';
import { PaginationModel } from '@/components/Table';
import { IS_PRODUCTION_MODE } from '@/config';
import { ExtractState, Params } from '@/types';

interface StoresStore {
  stores: Store[];
  searchQuery: StoresSearchQuery;
  pagination: PaginationModel;
  actions: {
    updateStores: (stores: Store[]) => void;
    updatePagination: (pagination: PaginationModel) => void;
    updateSearchQuery: (searchQuery: StoresSearchQuery) => void;
    resetState: () => void;
  };
}

const defaultPagination = {
  page: 1,
  pageSize: 10,
};

const storeStore = createStore<StoresStore>()(
  devtools(
    set => ({
      stores: [],
      searchQuery: { onlyActive: false },
      pagination: defaultPagination,
      actions: {
        updateStores: (stores: Store[]) => {
          set({
            stores,
          });
        },
        updatePagination: (pagination: PaginationModel) => {
          set({
            pagination,
          });
        },
        updateSearchQuery: (searchQuery: StoresSearchQuery) => {
          set({
            searchQuery,
          });
        },
        resetState: () => {
          set({
            stores: [],
            searchQuery: { onlyActive: false },
            pagination: defaultPagination,
          });
        },
      },
    }),
    {
      name: 'stores',
      enable: !IS_PRODUCTION_MODE,
    },
  ),
);

const useStoresStore = <U>(
  selector: Params<U, typeof storeStore>[1],
  equalityFn?: Params<U, typeof storeStore>[2],
) => {
  return useStoreWithEqualityFn(storeStore, selector, equalityFn);
};

// Selectors
const storesSelector = (state: ExtractState<typeof storeStore>) => state.stores;

const paginationSelector = (state: ExtractState<typeof storeStore>) =>
  state.pagination;

const searchQuerySelector = (state: ExtractState<typeof storeStore>) =>
  state.searchQuery;

const actionsSelector = (state: ExtractState<typeof storeStore>) =>
  state.actions;

// Getters
const getStores = () => storesSelector(storeStore.getState());

const getPagination = () => paginationSelector(storeStore.getState());

const getSearchQuery = () => searchQuerySelector(storeStore.getState());

const getActions = () => actionsSelector(storeStore.getState());

// Hooks
const useStores = () => useStoresStore(storesSelector, shallow);

const usePagination = () => useStoresStore(paginationSelector, shallow);

const useSearchQuery = () => useStoresStore(searchQuerySelector);

export {
  defaultPagination,

  // Getters
  getActions,
  getStores,
  getPagination,
  getSearchQuery,

  // Hooks
  useStores,
  usePagination,
  useSearchQuery,
};
