import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { DashboardSearchRequest } from '../types';

import { DashboardStore, GetGraphResponse } from '@/bundles/model';
import { PaginationModel } from '@/components/Table';
import { IS_PRODUCTION_MODE } from '@/config';
import { ExtractState, Params } from '@/types';

interface DashboardStoreInterface {
  dashboardStoreData: DashboardStore[];
  searchQuery: DashboardSearchRequest;
  pagination: PaginationModel;
  graphData: GetGraphResponse | null;
  actions: {
    updateDashboardStoreData: (dashboardStoreData: DashboardStore[]) => void;
    updatePagination: (pagination: PaginationModel) => void;
    updateSearchQuery: (searchQuery: DashboardSearchRequest) => void;
    updateGraphData: (graphData: GetGraphResponse) => void;
    resetState: () => void;
  };
}

const defaultPagination = {
  page: 1,
  pageSize: 10,
};

const dashboardStore = createStore<DashboardStoreInterface>()(
  devtools(
    set => ({
      dashboardStoreData: [],
      searchQuery: {},
      pagination: defaultPagination,
      graphData: null,
      actions: {
        updateDashboardStoreData: (dashboardStoreData: DashboardStore[]) => {
          set({
            dashboardStoreData,
          });
        },
        updatePagination: (pagination: PaginationModel) => {
          set({
            pagination,
          });
        },
        updateSearchQuery: (searchQuery: DashboardSearchRequest) => {
          set({
            searchQuery,
          });
        },
        updateGraphData: (graphData: GetGraphResponse) => {
          set({
            graphData,
          });
        },
        resetState: () => {
          set({
            dashboardStoreData: [],
            searchQuery: {},
            pagination: defaultPagination,
            graphData: null,
          });
        },
      },
    }),
    {
      name: 'dashboard',
      enable: !IS_PRODUCTION_MODE,
    },
  ),
);

const useDashboardStore = <U>(
  selector: Params<U, typeof dashboardStore>[1],
  equalityFn?: Params<U, typeof dashboardStore>[2],
) => {
  return useStoreWithEqualityFn(dashboardStore, selector, equalityFn);
};

// Selectors
const dashboardStoreDataSelector = (
  state: ExtractState<typeof dashboardStore>,
) => state.dashboardStoreData;

const paginationSelector = (state: ExtractState<typeof dashboardStore>) =>
  state.pagination;

const searchQuerySelector = (state: ExtractState<typeof dashboardStore>) =>
  state.searchQuery;

const graphDataSelector = (state: ExtractState<typeof dashboardStore>) =>
  state.graphData;

const actionsSelector = (state: ExtractState<typeof dashboardStore>) =>
  state.actions;

// Getters
const getDashboardStoreData = () =>
  dashboardStoreDataSelector(dashboardStore.getState());

const getPagination = () => paginationSelector(dashboardStore.getState());

const getSearchQuery = () => searchQuerySelector(dashboardStore.getState());

const getGraphData = () => graphDataSelector(dashboardStore.getState());

const getActions = () => actionsSelector(dashboardStore.getState());

// Hooks
const useDashboardStoreDatas = () =>
  useDashboardStore(dashboardStoreDataSelector, shallow);

const usePagination = () => useDashboardStore(paginationSelector, shallow);

const useGraphData = () => useDashboardStore(graphDataSelector, shallow);

export {
  defaultPagination,

  // Getters
  getActions,
  getDashboardStoreData,
  getPagination,
  getSearchQuery,
  getGraphData,

  // Hooks
  useDashboardStoreDatas,
  usePagination,
  useGraphData,
};
