import { getCases } from '../api';
import { CaseSearchRequest } from '../types';
import { CaseStatus } from '../utils/constants';

import { CaseBadgeContent } from '@/components/SideBar/types';

type CaseNumber = {
  [key: string]: number;
};

export const useFetchNumberOfCases = () => {
  const fetchNumberOfCase = async (): Promise<CaseBadgeContent> => {
    try {
      const pagination = {
        page: 1,
        pageSize: 1,
      };

      const searchRequests: CaseSearchRequest[] = [
        {
          ...pagination,
          emlinxStatus: '', // all status
        },
        {
          ...pagination,
          emlinxStatus: CaseStatus.ApprovalPending,
        },
        {
          ...pagination,
          emlinxStatus: CaseStatus.Temporary,
        },
      ];

      const results: CaseNumber = {};

      const promises = searchRequests.map(async query => {
        let { emlinxStatus } = query;
        emlinxStatus = emlinxStatus === '' ? CaseStatus.All : emlinxStatus;
        try {
          const data = await getCases(query);
          results[emlinxStatus!] = data.pagination?.totalRecords || 0;
        } catch (error) {
          results[emlinxStatus!] = 0;
          console.error(`Error fetching ${query.emlinxStatus}:`, error);
        }
      });

      await Promise.all(promises);

      return {
        all: results[CaseStatus.All],
        approvalPending: results[CaseStatus.ApprovalPending],
        temporary: results[CaseStatus.Temporary],
      };
    } catch (error) {
      console.error(error);
      return {
        all: 0,
        approvalPending: 0,
        temporary: 0,
      };
    }
  };

  return {
    fetchNumberOfCase,
  };
};
