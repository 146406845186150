import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperCommonProps } from './FieldWrapper';

const useStyles = makeStyles(() =>
  createStyles({
    inputText: {
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 'unset',
      },
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: 'unset',
      },
      '& .MuiIconButton-root': {
        padding: '4px',
        marginRight: 'unset',
      },
      '& .MuiFormHelperText-root': {
        marginRight: 'unset',
        marginLeft: 'unset',
      },
    },
  }),
);

type Props = {
  /**
   * Registration form props for react-hook-form.
   */
  registrationForm?: Partial<UseFormRegisterReturn>;
  subLabelPassword?: string;
};

export type InputTextProps = Props &
  Omit<FieldWrapperCommonProps, 'id'> &
  TextFieldProps;

export const InputText: React.FC<InputTextProps> = ({
  label,
  labelPosition,
  registrationForm = {},
  subLabelPassword,
  ...restProps
}: InputTextProps) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const isPassword = restProps.type === 'password';

  const renderTogglePasswordButton = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(prev => !prev)}
          edge="end"
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    <FieldWrapper
      id={restProps.id}
      label={label}
      labelPosition={labelPosition}
      required={restProps.required}
      subLabelPassword={subLabelPassword}
    >
      <TextField
        {...restProps}
        type={isPassword && showPassword ? 'text' : restProps.type || 'text'}
        InputProps={{
          ...restProps.InputProps,
          endAdornment: isPassword
            ? renderTogglePasswordButton()
            : restProps?.InputProps?.endAdornment,
        }}
        {...registrationForm}
        className={clsx(classes.inputText, restProps.className)}
      />
    </FieldWrapper>
  );
};

InputText.defaultProps = {
  labelPosition: 'top',
  type: 'text',
};
