const localStoragePrefix = 'emlinx_app_';

const storage = {
  get: (name: string): string => {
    return localStorage.getItem(`${localStoragePrefix}${name}`) || '';
  },
  set: (name: string, value: string) => {
    localStorage.setItem(`${localStoragePrefix}${name}`, value);
  },
  clear: (name: string) => {
    localStorage.removeItem(`${localStoragePrefix}${name}`);
  },
  clearAll: () => {
    localStorage.clear();
  },
};

export default storage;
