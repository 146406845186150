import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { CasesSearchQuery } from '../types';

import { Case, GetCasesSortByEnum } from '@/bundles/model';
import { PaginationModel, SortField } from '@/components/Table';
import { IS_PRODUCTION_MODE } from '@/config';
import { ExtractState, Params } from '@/types';

interface CaseStore {
  cases: Case[];
  searchQuery: CasesSearchQuery;
  pagination: PaginationModel;
  sort: CaseSort;
  actions: {
    updateCases: (cases: Case[]) => void;
    updatePagination: (pagination: PaginationModel) => void;
    updateSearchQuery: (searchQuery: CasesSearchQuery) => void;
    updateSort: (sort: CaseSort) => void;
    resetState: () => void;
  };
}

const defaultPagination = {
  page: 1,
  pageSize: 10,
};

export type CaseSort = SortField<GetCasesSortByEnum>;

const defaultSort: CaseSort = {
  sortBy: ['occurrence_date', 'last_update'],
  sortOrder: ['desc', 'desc'],
};

const caseStore = createStore<CaseStore>()(
  devtools(
    set => ({
      cases: [],
      searchQuery: {},
      pagination: defaultPagination,
      sort: defaultSort,
      actions: {
        updateCases: (cases: Case[]) => {
          set({
            cases,
          });
        },
        updatePagination: (pagination: PaginationModel) => {
          set({
            pagination,
          });
        },
        updateSearchQuery: (searchQuery: CasesSearchQuery) => {
          set({
            searchQuery,
          });
        },
        updateSort: (sort: CaseSort) => {
          set({ sort });
        },
        resetState: () => {
          set({
            cases: [],
            searchQuery: {},
            pagination: defaultPagination,
            sort: defaultSort,
          });
        },
      },
    }),
    {
      name: 'cases',
      enable: !IS_PRODUCTION_MODE,
    },
  ),
);

const useCasesStore = <U>(
  selector: Params<U, typeof caseStore>[1],
  equalityFn?: Params<U, typeof caseStore>[2],
) => {
  return useStoreWithEqualityFn(caseStore, selector, equalityFn);
};

// Selectors
const casesSelector = (state: ExtractState<typeof caseStore>) => state.cases;

const paginationSelector = (state: ExtractState<typeof caseStore>) =>
  state.pagination;

const searchQuerySelector = (state: ExtractState<typeof caseStore>) =>
  state.searchQuery;

const actionsSelector = (state: ExtractState<typeof caseStore>) =>
  state.actions;

const sortSelector = (state: ExtractState<typeof caseStore>) => state.sort;

// Getters
const getCases = () => casesSelector(caseStore.getState());

const getPagination = () => paginationSelector(caseStore.getState());

const getSearchQuery = () => searchQuerySelector(caseStore.getState());

const getActions = () => actionsSelector(caseStore.getState());

const getSort = () => sortSelector(caseStore.getState());

// Hooks
const useCases = () => useCasesStore(casesSelector, shallow);

const usePagination = () => useCasesStore(paginationSelector, shallow);

const useSort = () => useCasesStore(sortSelector, shallow);

const useSearchQuery = () => useCasesStore(searchQuerySelector, shallow);

export {
  defaultPagination,

  // Getters
  getActions,
  getCases,
  getPagination,
  getSearchQuery,
  getSort,

  // Hooks
  useCases,
  usePagination,
  useSort,
  useSearchQuery,
};
