import type { AxiosRequestConfig } from 'axios';

import { ListMenuResponse, MenuApi } from '@/bundles/model/';
import { ApiConfiguration } from '@/lib/swagger';

const menuAPI = new MenuApi(new ApiConfiguration().configuration);

export const getMenu = async (
  options?: AxiosRequestConfig,
): Promise<ListMenuResponse> => {
  const response = await menuAPI.getMenu(options);

  return response.data;
};
