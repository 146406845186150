import type { AxiosRequestConfig } from 'axios';

import { HitApi } from '@/bundles/model/';
import { ApiConfiguration } from '@/lib/swagger';

const hitAPI = new HitApi(new ApiConfiguration().configuration);

export const createHitAPI = async (
  options?: AxiosRequestConfig,
): Promise<void> => {
  const response = await hitAPI.postHit(options);

  return response.data;
};
