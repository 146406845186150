import {
  DatePicker,
  LocalizationProvider,
  DatePickerProps,
} from '@mui/x-date-pickers';
// with date-fns v3.x
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ja } from 'date-fns/locale/ja';
import React from 'react';

import { FieldWrapper, FieldWrapperCommonProps } from './FieldWrapper';

import { DateFormat } from '@/utils/constant';

type Props = {
  /**
   * Date format.
   */
  dateFormat?: string;
  /**
   * 	If true, the label is displayed in an error state.
   */
  error?: boolean;
  /**
   *  The helper text content.
   */
  helperText?: React.ReactNode;

  placeholder?: string;
};

export type InputDateProps = Props &
  FieldWrapperCommonProps &
  DatePickerProps<Date>;

export const InputDate: React.FC<InputDateProps> = ({
  id = 'date-picker-id',
  label,
  labelPosition,
  required,
  dateFormat = DateFormat.fullDateDDMMYYYYWithSlash,
  error,
  helperText,
  placeholder,
  ...restProps
}: InputDateProps) => {
  const textPlaceholder = () => (placeholder ? { placeholder } : {});

  return (
    <FieldWrapper
      id={id}
      label={label}
      labelPosition={labelPosition}
      required={required}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <DatePicker
          format={dateFormat}
          slotProps={{
            textField: {
              ...restProps?.slotProps?.textField,
              id: id,
              error: error,
              helperText: helperText,
              ...textPlaceholder(),
            },
          }}
          minDate={restProps.minDate ?? new Date(1000, 1, 1)}
          {...restProps}
        />
      </LocalizationProvider>
    </FieldWrapper>
  );
};

InputDate.defaultProps = {
  labelPosition: 'top',
};
