import { useEffect } from 'react';

import { getMenu } from '../apis/menu';
import { getMenusAction } from '../stores/menuStore';

export const useFetchMenu = (initial = false) => {
  const { updateMenus } = getMenusAction();

  const fetchMenu = async () => {
    try {
      const searchRes = await getMenu({});

      const { menus } = searchRes || {};

      updateMenus(menus ?? []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!initial) {
      return;
    }

    fetchMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    fetchMenu,
  };
};
