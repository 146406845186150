import type { AxiosRequestConfig } from 'axios';

import {
  ForgotpasswordtokenApi,
  ForgotPasswordTokenRequest,
} from '@/bundles/model/';
import { ApiConfiguration } from '@/lib/swagger';

const forgotPasswordAPI = new ForgotpasswordtokenApi(
  new ApiConfiguration().configuration,
);

const validateForgotPasswordToken = async (
  token: string,
  options?: AxiosRequestConfig,
): Promise<void> => {
  const res = await forgotPasswordAPI.getForgotPasswordToken(token, options);
  return res.data;
};

const resetPasswordToken = async (
  token: string,
  forgotPasswordTokenRequest?: ForgotPasswordTokenRequest,
  options?: AxiosRequestConfig,
) => {
  const res = await forgotPasswordAPI.postForgotPasswordToken(
    token,
    forgotPasswordTokenRequest,
    options,
  );

  return res.data;
};

export { validateForgotPasswordToken, resetPasswordToken };
