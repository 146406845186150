import { Toolbar, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import clsx from 'clsx';
import { useState } from 'react';

import { AppBar } from '../AppBar';
import { SideBar, SIDEBAR_WIDTH } from '../SideBar';

type MainLayoutProps = {
  children: React.ReactNode;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: '#F4F6F9',
      minHeight: '100vh',
    },
    content: {
      transition: 'width 500ms ease-in-out',
      marginTop: '8px',
    },
    contentOpen: {
      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
    },
    contentClosed: {
      width: '100%',
    },
    contentSmallScreen: {
      width: '100%',
    },
  }),
);

export const MainLayout: React.FC<MainLayoutProps> = ({
  children,
}: MainLayoutProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const [sideBarOpen, setSidebarOpen] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <AppBar setSidebarOpen={setSidebarOpen} />
      <SideBar open={sideBarOpen} setSidebarOpen={setSidebarOpen} />
      <main
        className={clsx(classes.content, {
          [classes.contentOpen]: sideBarOpen,
          [classes.contentClosed]: !sideBarOpen,
          [classes.contentSmallScreen]: matchesSM,
        })}
      >
        <Toolbar />
        {children}
      </main>
    </div>
  );
};
