import { CardMedia, Container, Grid, Theme, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import EmlinxLogo from '@/assets/emlinx_logo_color.png';
import TakachioLogo from '@/assets/takachio_logo_color.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    authLayout: {
      display: 'flex',
      width: '100%',
      height: '100%',
      backgroundColor: '#FFFFFF',
    },
    container: {
      '& > *:not(:first-child)': {
        marginTop: '48px',
      },
      '&__subtitle': {
        marginTop: '8px',
        fontWeight: 'normal',
      },
      '@media (min-width: 600px)': {
        marginTop: theme.spacing(14),
      },
    },
  }),
);

/**
 * Props for AuthLayout component.
 */
type AuthLayoutProps = {
  /**
   * The element inside which wrapped by the auth layout.
   */
  children: React.ReactNode;
};

export const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
}: AuthLayoutProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.authLayout}>
      <Container className={classes.container}>
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <CardMedia
            component="img"
            image={EmlinxLogo}
            alt="logo"
            style={{ maxWidth: '200px', maxHeight: '200px' }}
          />
          <Typography variant="h1" textAlign="center">
            {t('auth.title')}
          </Typography>
          <Typography variant="h2" className={`${classes.container}__subtitle`}>
            {t('auth.description')}
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center">
          {children}
        </Grid>
        <Grid item container justifyContent="center" alignItems="center">
          <CardMedia
            component="img"
            image={TakachioLogo}
            alt="logo"
            style={{ maxWidth: '200px', maxHeight: '200px' }}
          />
        </Grid>
      </Container>
    </div>
  );
};
