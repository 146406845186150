import { Button, Divider, Paper, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';

import { routes } from '@/routes';

const useStyles = makeStyles(() =>
  createStyles({
    statusFrame: {
      padding: '20px',
      maxWidth: '400px',
      width: '100%',
      '& > *:not(:first-child)': {
        marginTop: '16px',
      },
      '&__title': {
        fontWeight: 'normal',
        textAlign: 'center',
      },
      '&__subtitle': {
        color: '#686868',
      },
      '&__link': {
        color: '#4695D9',
        width: 'fit-content',
      },
      '&__button': {
        width: '100%',
      },
    },
  }),
);

type ResetPasswordStatusProps = {
  isSuccess: boolean;
};

export const ResetPasswordStatus: React.FC<ResetPasswordStatusProps> = ({
  isSuccess,
}: ResetPasswordStatusProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  if (
    !state?.prevPath ||
    state?.prevPath !== routes.auth.forgotPassword.reset
  ) {
    return (
      <Navigate
        to={`${routes.auth.forgotPassword.base}${routes.auth.forgotPassword.request}`}
        replace
      />
    );
  }

  return (
    <Paper className={classes.statusFrame} elevation={5}>
      <Typography variant="h2" className={`${classes.statusFrame}__title`}>
        {t('auth.passwordRecover.title.resetPassword')}
      </Typography>
      <Divider />
      <Typography variant="body2" align="center">
        {isSuccess
          ? t('auth.passwordRecover.status.success')
          : t('auth.passwordRecover.status.linkExpired')}
      </Typography>
      <Button
        variant="contained"
        size="medium"
        type="submit"
        className={`${classes.statusFrame}__button`}
        onClick={() => navigate(routes.auth.login, { replace: true })}
      >
        {t('auth.passwordRecover.status.button.returnToLoginScreen')}
      </Button>
    </Paper>
  );
};
