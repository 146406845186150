export enum DateFormat {
  fullDateTimeWithHyphen = 'yyyy-MM-dd HH:mm',
  fullDateWithHyphen = 'yyyy-MM-dd',
  timeWithoutSeconds = 'HH:mm',
  fullJpDate = 'yyyy年MM月dd日',
  fullJpYearMonthWithSlash = 'yyyy年MM月',
  fullDateISO8601WithOffset = "yyyy-MM-dd'T'HH:mm:ssxxx",
  fullDateDDMMYYYYWithSlash = 'dd/MM/yyyy',
  fullDateWithSlash = 'yyyy/MM/dd',
  fullDateISO8601UTC = 'yyyy-MM-ddTHH:mm:ssZ',
  fullDateTimeYYYYMMDDWithSlash = 'yyyy/MM/dd HH:mm:ss',
  fullDateWithDayOfWeekJp = 'yyyy年MM月dd日(EE)',
  fullDateTimeWithoutSeparator = 'yyyyMMddHHmmss',
}
