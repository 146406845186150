import type { AxiosRequestConfig } from 'axios';

import { QueryUserStore } from '../types';

import { ListUserStoresResponse, ListuserstoresApi } from '@/bundles/model';
import { ApiConfiguration } from '@/lib/swagger';

const listUserStoresAPI = new ListuserstoresApi(
  new ApiConfiguration().configuration,
);

export const getUserStores = async (
  query: QueryUserStore,
  options?: AxiosRequestConfig,
): Promise<ListUserStoresResponse> => {
  const response = await listUserStoresAPI.listUserStores(
    query.companyId,
    query.storeName,
    query.includeInProject,
    query.page,
    query.size,
    query.onlyActive,
    options,
  );

  if (response.status !== 200) {
    throw new Error('getUserStores failed!');
  }

  return response.data;
};
