import type { AxiosRequestConfig } from 'axios';

import { LoginApi, LoginRequest, LoginResponse } from '@/bundles/model/';
import { ApiConfiguration } from '@/lib/swagger';

const loginAPI = new LoginApi(new ApiConfiguration().configuration);

export const login = async (
  loginRequest?: LoginRequest,
  options?: AxiosRequestConfig,
): Promise<LoginResponse> => {
  const response = await loginAPI.postLogin(loginRequest, options);
  if (!response.data) {
    throw new Error();
  }

  return response.data;
};
