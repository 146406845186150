import { Box } from '@mui/material';
import React from 'react';

interface MaterialFontIconProps {
  icon: string;
}

const MaterialFontIcon: React.FC<MaterialFontIconProps> = ({ icon }) => {
  return (
    <Box
      component="i"
      className="material-icons"
      sx={{
        color: '#000000',
      }}
      translate='no'
    >
      {icon}
    </Box>
  );
};

export default MaterialFontIcon;
