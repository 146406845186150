import type { AxiosRequestConfig } from 'axios';

import { ListProductsResponse, ListproductsApi } from '@/bundles/model';
import { ApiConfiguration } from '@/lib/swagger';

const listProductsAPI = new ListproductsApi(
  new ApiConfiguration().configuration,
);

export const getProducts = async (
  janCode?: string,
  productName?: string,
  page: number = 1,
  size: number = 100,
  options?: AxiosRequestConfig,
): Promise<ListProductsResponse> => {
  const response = await listProductsAPI.listProducts(
    janCode,
    productName,
    page,
    size,
    options,
  );

  if (response.status !== 200) {
    throw new Error('getProducts failed!');
  }

  return response.data;
};
