import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AuthProvider } from './auth';

import { MODE } from '@/config';
import { useOnMount } from '@/hooks/useOnMount';
import { initializeGoogleAnalytics } from '@/lib/ga';
import { theme } from '@/lib/muiTheme';

const ErrorFallback = () => {
  return <div>Something went wrong</div>;
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider: React.FC<AppProviderProps> = ({
  children,
}: AppProviderProps) => {
  //* Initialize Google Analytics for production env
  useOnMount(() => {
    if (MODE === 'production') {
      initializeGoogleAnalytics();
    }
  });

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ToastContainer
        autoClose={3000}
        limit={3}
        position="top-right"
        theme="colored"
        hideProgressBar
      />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Router>{children}</Router>
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
};
