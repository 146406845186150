import axios from 'axios';

import { refreshToken } from '@/features/auth';
import { routes } from '@/routes/routes';
import { is401Error } from '@/utils';
import storage from '@/utils/localstorage';

const getAccessToken = () => storage.get('access_token');

const isLoggedIn = () => Boolean(getAccessToken());

let refreshTokenRequest: Promise<string | null> | null = null;

axios.interceptors.request.use(
  config => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

axios.interceptors.response.use(
  response => response,
  async error => {
    if (is401Error(error)) {
      const config = error.config;

      if (!isLoggedIn()) {
        return Promise.reject(error);
      }

      if (!refreshTokenRequest) {
        refreshTokenRequest = refreshToken({ withCredentials: true })
          .then(res => res.jwt)
          .catch(error => {
            console.error('Failed to retrieve refresh token', error);
            return null;
          });
      }

      const newToken = await refreshTokenRequest;
      refreshTokenRequest = null;

      if (newToken) {
        storage.set('access_token', newToken);
        config.headers.Authorization = `Bearer ${newToken}`;
        return axios(config);
      } else {
        storage.clear('access_token');
        storage.clear('menu_active_route');
        window.location.replace(routes.auth.login);
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  },
);

export default axios;
