import type { AxiosRequestConfig } from 'axios';

import {
  ListCaseStatusCountResponse,
  GetcasesstatuscountApi,
} from '@/bundles/model';
import { ApiConfiguration } from '@/lib/swagger';

const getcasesstatuscountApi = new GetcasesstatuscountApi(
  new ApiConfiguration().configuration,
);

export const getStatusCount = async (
  status?: string[],
  options?: AxiosRequestConfig,
): Promise<ListCaseStatusCountResponse> => {
  const response = await getcasesstatuscountApi.listCaseStatusCount(
    status,
    options,
  );

  if (response.status === 204) {
    return { records: [] };
  }

  if (response.status !== 200) {
    throw new Error('getStatusCount failed!');
  }

  return response.data;
};
