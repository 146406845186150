import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { CompanySearchRequest } from '../types';

import { Company } from '@/bundles/model';
import { PaginationModel } from '@/components/Table';
import { IS_PRODUCTION_MODE } from '@/config';
import { ExtractState, Params } from '@/types';

interface CompaniesStore {
  companies: Company[];
  searchQuery: CompanySearchRequest;
  pagination: PaginationModel;
  actions: {
    updateCompanies: (companies: Company[]) => void;
    updatePagination: (pagination: PaginationModel) => void;
    addSearchQuery: (searchQuery: CompanySearchRequest) => void;
    resetState: () => void;
  };
}

const defaultPagination = {
  page: 1,
  pageSize: 10,
};

const companiesStore = createStore<CompaniesStore>()(
  devtools(
    set => ({
      companies: [],
      searchQuery: {} as CompanySearchRequest,
      pagination: defaultPagination,
      actions: {
        updateCompanies: (companies: Company[]) => {
          set({
            companies,
          });
        },
        updatePagination: (pagination: PaginationModel) => {
          set({
            pagination,
          });
        },
        addSearchQuery: (searchQuery: CompanySearchRequest) => {
          set({
            searchQuery,
          });
        },
        resetState: () => {
          set({
            companies: [],
            searchQuery: {} as CompanySearchRequest,
            pagination: defaultPagination,
          });
        },
      },
    }),
    {
      name: 'companies',
      enable: !IS_PRODUCTION_MODE,
    },
  ),
);

const useCompaniesStore = <U>(
  selector: Params<U, typeof companiesStore>[1],
  equalityFn?: Params<U, typeof companiesStore>[2],
) => {
  return useStoreWithEqualityFn(companiesStore, selector, equalityFn);
};

// Selectors
const companiesSelector = (state: ExtractState<typeof companiesStore>) =>
  state.companies;

const paginationSelector = (state: ExtractState<typeof companiesStore>) =>
  state.pagination;

const searchQuerySelector = (state: ExtractState<typeof companiesStore>) =>
  state.searchQuery;

const actionsSelector = (state: ExtractState<typeof companiesStore>) =>
  state.actions;

// Getters
const getCompanies = () => companiesSelector(companiesStore.getState());

const getPagination = () => paginationSelector(companiesStore.getState());

const getSearchQuery = () => searchQuerySelector(companiesStore.getState());

const getActions = () => actionsSelector(companiesStore.getState());

// Hooks
const useCompanies = () => useCompaniesStore(companiesSelector, shallow);
const usePagination = () => useCompaniesStore(paginationSelector, shallow);

export {
  // Getters
  getActions,
  getCompanies,
  getPagination,
  getSearchQuery,

  // Hooks
  useCompanies,
  usePagination,
};
