import type { AxiosRequestConfig } from 'axios';

import { ListMasterItemsResponse, ListmasteritemsApi } from '@/bundles/model';
import { ApiConfiguration } from '@/lib/swagger';

const listMasterItemsAPI = new ListmasteritemsApi(
  new ApiConfiguration().configuration,
);

export const getMasterItems = async (
  types: string[] = [],
  options?: AxiosRequestConfig,
): Promise<ListMasterItemsResponse> => {
  const response = await listMasterItemsAPI.listMasterItems(types, options);

  if (response.status !== 200) {
    throw new Error('getMasterItems failed!');
  }

  return response.data;
};
