import WestIcon from '@mui/icons-material/West';
import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { forgotPassword } from '../api/forgotPassword';

import { routes } from '@/routes';

const useStyles = makeStyles(() =>
  createStyles({
    instructionFrame: {
      padding: '20px',
      maxWidth: '400px',
      width: '100%',
      '& > *:not(:first-child)': {
        marginTop: '16px',
      },
      '&__title': {
        fontWeight: 'normal',
        textAlign: 'center',
      },
      '&__subtitle': {
        color: '#686868',
      },
      '&__link': {
        color: '#4695D9',
        width: 'fit-content',
      },
      '&__button': {
        width: '100%',
      },
    },
    returnButton: {
      marginTop: '16px',
    },
  }),
);

export const ResetPasswordInstruction = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { state } = useLocation();

  if (!state?.email) {
    return (
      <Navigate
        to={`${routes.auth.forgotPassword.base}${routes.auth.forgotPassword.request}`}
        replace
      />
    );
  }

  const submitResendUrl = async () => {
    try {
      await forgotPassword({
        email: state.email,
      });

      toast.success(t('auth.passwordRecover.instruction.toast.resendSuccess'));
    } catch (error) {
      console.error(error);
      toast.error(t('auth.passwordRecover.instruction.toast.resendError'));
    }
  };

  return (
    <Grid>
      <Paper className={classes.instructionFrame} elevation={5}>
        <Typography
          variant="h2"
          className={`${classes.instructionFrame}__title`}
        >
          {t('auth.passwordRecover.title.forgotPassword')}
        </Typography>
        <Typography
          variant="body2"
          className={`${classes.instructionFrame}__subtitle`}
        >
          {t('auth.passwordRecover.instruction.resendRequestInstruction')}
        </Typography>
        <Divider className={`${classes.instructionFrame}__divider`} />
        <Grid>
          <Typography variant="body2">
            {t('auth.passwordRecover.instruction.urlReminder')}
          </Typography>
          <Typography variant="body2">
            {t('auth.passwordRecover.instruction.mailLocationReminder')}
          </Typography>
        </Grid>
        <Button
          variant="contained"
          size="medium"
          type="submit"
          className={`${classes.instructionFrame}__button`}
          onClick={submitResendUrl}
        >
          {t('auth.passwordRecover.instruction.button.resendUrl')}
        </Button>
      </Paper>
      <Button
        to={-1 as any}
        component={RouterLink}
        startIcon={<WestIcon />}
        className={classes.returnButton}
      >
        {t('common.button.returnPreviousScreen')}
      </Button>
    </Grid>
  );
};
