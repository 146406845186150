import { CircularProgress, Grid } from '@mui/material';
import { Enforcer, StringAdapter, newEnforcer, newModel } from 'casbin';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { User } from '@/bundles/model';
import {
  LoginValues,
  getAuthUser as getAuthUserAPI,
  login,
} from '@/features/auth';
import { getPolicies as getPoliciesAPI } from '@/features/authorization';
import { useFetchMenu } from '@/features/menu/hooks/useFetchMenu';
import storage from '@/utils/localstorage';

type AuthContextType = {
  authUser: User | undefined;
  policies: Enforcer | undefined;
  justLogin: boolean;
  loginFn: (loginVal: LoginValues) => Promise<boolean>;
  logoutFn: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

type AuthProviderProps = {
  children: ReactNode;
};

const LoaderComponent = () => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    sx={{ height: '100vh' }}
  >
    <CircularProgress />
  </Grid>
);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const { t } = useTranslation();
  const { fetchMenu } = useFetchMenu();

  const [authUser, setAuthUser] = useState<User | undefined>(undefined);
  const [policies, setPolicies] = useState<Enforcer>();
  const [isInitialize, setInitialize] = useState<boolean>(false);
  const [justLogin, setJustLogin] = useState<boolean>(false);

  const loginFn = async ({ email, password }: LoginValues) => {
    try {
      const { jwt } = await login(
        { email, password },
        {
          withCredentials: true,
        },
      );
      setJustLogin(true);
      storage.set('access_token', jwt);
      await initUserAndPolicies(jwt);
      toast.success(t('auth.login.toast.loginSuccess'));
      return true;
    } catch (error) {
      console.error(error);
      toast.error(t('auth.login.toast.loginFailed'));
      return false;
    }
  };

  const loadUser = async (accessToken: string) => {
    try {
      const { sub } = jwtDecode<JwtPayload>(accessToken);
      const authUser = await getAuthUserAPI(Number(sub));
      return authUser;
    } catch (error) {
      console.error('Failed to load user:', error);
    }
  };

  const loadPolices = async () => {
    try {
      const policiesRes = await getPoliciesAPI();
      const { casbinModel, casbinPolicies } = policiesRes || {};
      const enforcer = await newEnforcer(
        newModel(casbinModel),
        new StringAdapter(casbinPolicies),
      );

      return enforcer;
    } catch (error) {
      console.error('Failed to load policies:', error);
    }
  };

  const initUserAndPolicies = async (accessToken: string) => {
    const [authUser, enforcer] = await Promise.all([
      loadUser(accessToken),
      loadPolices(),
      fetchMenu(),
    ]);

    if (!authUser || !enforcer) {
      storage.clear('access_token');
      throw new Error('Failed to load user and policies');
    }

    setAuthUser(authUser);
    setPolicies(enforcer);
  };

  const logoutFn = () => {
    storage.clear('access_token');
    storage.clear('menu_active_route');
    window.location.assign(window.location.origin as unknown as string);
  };

  // * Initialize the app run
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const accessToken = storage.get('access_token');
        if (accessToken) {
          await initUserAndPolicies(accessToken);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setInitialize(true);
      }
    };

    initializeAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue: AuthContextType = {
    authUser,
    policies,
    justLogin,
    loginFn,
    logoutFn,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {isInitialize ? children : <LoaderComponent />}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be wrapped by AuthProvider');
  }

  return context;
};

// eslint-disable-next-line react-refresh/only-export-components
export { AuthProvider, useAuth };
