const HttpResponseStatusCodes = {
  // Informational responses

  // Successful responses
  OK: 200,
  NO_CONTENT: 204,

  // Redirection messages

  // Client error responses
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,

  // Server error responses
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
} as const;

export { HttpResponseStatusCodes };
