const AccessEntities = {
  // Industry features
  MenuIndustries: 'menu:industries',
  FeatureListIndustries: 'feat:listindustries',
  FeatureCreateIndustry: 'feat:createindustry',
  FeatureUpdateIndustry: 'feat:updateindustry',
  FeatureDeleteIndustry: 'feat:deleteindustry',

  // Account management menu
  MenuAccounts: 'menu:accounts',

  // Administrator features
  MenuAdministrators: 'menu:administrators',
  FeatureListAdministrators: 'feat:listadministrators',
  FeatureCreateAdministrator: 'feat:createadministrator',
  FeatureReadAdministrator: 'feat:readadministrator',
  FeatureUpdateAdministrator: 'feat:updateadministrator',
  FeatureDeleteAdministrator: 'feat:deleteadministrator',

  // Company features
  MenuCompanies: 'menu:companies',
  FeatureListCompanies: 'feat:listcompanies',
  FeatureCreateCompany: 'feat:createcompany',
  FeatureReadCompany: 'feat:readcompany',
  FeatureUpdateCompany: 'feat:updatecompany',
  FeatureDeleteCompany: 'feat:deletecompany',
  FeaturePatchCompany: 'feat:patchcompany',
  FeatureStopCompany: 'feat:stopcompany',

  // Store features
  MenuStores: 'menu:stores',
  FeatureListStores: 'feat:liststores',
  FeatureCreateStore: 'feat:createstore',
  FeatureReadStore: 'feat:readstore',
  FeatureUpdateStore: 'feat:updatestore',
  FeatureDeleteStore: 'feat:deletestore',
  FeatureStopStore: 'feat:stopstore',
  FeatureRestoreStore: 'feat:restorestore',
  FeatureImportStore: 'feat:importstore',

  FormStoresCompany: 'form:stores.company',
  FormStoresLoginId: 'form:stores.loginid',
  FormStoresAccountType: 'form:stores.accounttype',
  FormStoresPassword: 'form:stores.password',
  FormStoresConfirmPassword: 'form:stores.confirmpassword',
  FormStoresReceptAllCaseRegister: 'form:stores.receptallcaseregister',
  FormStoresReceptAllCaseEdit: 'form:stores.receptallcaseedit',
  FormStoresReceptionProject: 'form:stores.receptionproject',
  FormStoresReceptionIndustry: 'form:stores.receptionindustry',
  FormStoresReceptionArea: 'form:stores.receptionarea',

  // FAQ features
  MenuFaqs: 'menu:faqs',
  FeatureListFaq: 'feat:listfaq',
  FeatureCreateFaq: 'feat:createfaq',
  FeatureUpdateFaq: 'feat:updatefaq',
  FeatureDeleteFaq: 'feat:deletefaq',

  // Login info features
  MenuLoginInfo: 'menu:logininfo',

  // Dashboard features
  MenuDashBoard: 'menu:dashboard',

  FeatureDashboardSearchWithHyphen: 'feat:dashboardsearchwithhyphen',

  // Cases features
  MenuCases: 'menu:cases',
  MenuCasesAll: 'menu:casesall',
  MenuCaseApprovalPending: 'menu:casesapprovalpending',
  MenuCaseEditing: 'menu:casesediting',

  FeatureListCases: 'feat:listcases',
  FeatureCreateCase: 'feat:createcase',
  FeatureReadCase: 'feat:readcase',
  FeatureUpdateCase: 'feat:updatecase',
  FeatureDeleteCase: 'feat:deletecase',
  FeatureUpdateCaseAttactment: 'feat:updatecase.attachment',
  FeatureImportCase: 'feat:importcase',
  FeatureUpdateCaseCompany: 'feat:updatecase.company',
  FeatureUpdateCaseStore: 'feat:updatecase.store',

  FeatureListCasesCompany: 'feat:listcases.company',
  FeatureListCasesProject: 'feat:listcases.project',
  FeatureListCasesAttachment: 'feat:listcases.attachment',

  FeatureCaseStoreUpdateSelf: 'feat:cases.store.updateself',
  FeatureCaseApprovalPending: 'feat:approvecase',
  FeatureCaseSearchWithHyphen: 'feat:casesearchwithhyphen',

  // Projects features
  MenuProjects: 'menu:projects',

  FeatureCreateProject: 'feat:createproject',
  FeatureReadProject: 'feat:readproject',
  FeatureUpdateProject: 'feat:updateproject',
  FeatureDeleteProject: 'feat:deleteproject',
  FeatureRemoveProjectStore: 'feat:removeprojectstore',

  // Guidelines features
  MenuGuidelines: 'menu:guidelines',
  FeatureListGuidelines: 'feat:listguidelines',
  FeatureCreateGuideline: 'feat:createguideline',
  FeatureReadGuideline: 'feat:readguideline',
  FeatureUpdateGuideline: 'feat:updateguideline',
  FeatureDeleteGuideline: 'feat:deleteguideline',
} as const;

const AuthorizeActions = {
  Create: 'create',
  CreateSelf: 'create.self',
  Read: 'read',
  ReadSelf: 'read.self',
  Update: 'update',
  UpdateSelf: 'update.self',
  Delete: 'delete',
  DeleteSelf: 'delete.self',
} as const;

const AuthorizeDomains = {
  FE: 'FE',
} as const;

/**
 * * Data was extracted from postgresql table name: role, column: id
 */
const AccountRoles = {
  EmlinxRoot: 1,
  Emlinx: 2,
  HeadquarterManager: 3,
  StoreManager: 4,
} as const;

const DEFAULT_AUTHORIZATION_ACTIONS = [
  AuthorizeActions.Create,
  AuthorizeActions.CreateSelf,
  AuthorizeActions.Read,
  AuthorizeActions.ReadSelf,
  AuthorizeActions.Update,
  AuthorizeActions.UpdateSelf,
  AuthorizeActions.Delete,
  AuthorizeActions.DeleteSelf,
];

export {
  AccessEntities,
  AccountRoles,
  AuthorizeActions,
  AuthorizeDomains,
  DEFAULT_AUTHORIZATION_ACTIONS,
};
