const isNumber = (val: unknown): val is number => {
  return typeof val === 'number' && !Number.isNaN(val) && Number.isFinite(val);
};

/**
 * Converts any value to Number
 */
const toNumber = (value: any) => {
  const num = parseFloat(value);
  return typeof num !== 'number' || Number.isNaN(num) ? 0 : num;
};

/**
 * Converts a value to a specific precision (or decimal points).
 * Precision must be between 1 and 100
 * Returns a string representing a number in fixed-point notation.
 */
const toPrecision = (value: number, precision?: number) => {
  let nextValue: string | number = toNumber(value);
  const scaleFactor = 10 ** (precision ?? 10);
  nextValue = Math.round(nextValue * scaleFactor) / scaleFactor;
  return precision ? nextValue.toFixed(precision) : nextValue.toString();
};

/**
 * Counts the number of decimal places a number has
 */
const countDecimalPlaces = (value: number) => {
  if (!Number.isFinite(value)) return 0;

  let e = 1;
  let p = 0;
  while (Math.round(value * e) / e !== value) {
    e *= 10;
    p += 1;
  }
  return p;
};

const fullWidthToHalfWidth = (value: string) => {
  return value.replace(/[０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};

const formatComma = (value: string | number) => {
  return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

export {
  isNumber,
  toNumber,
  countDecimalPlaces,
  toPrecision,
  fullWidthToHalfWidth,
  formatComma,
};
