import type { AxiosRequestConfig } from 'axios';
import { format } from 'date-fns';

import { CasesSearchQuery } from '../types';

import { ExportcasesApi, ExportCasesResponse } from '@/bundles/model';
import { ApiConfiguration } from '@/lib/swagger';
import { DateFormat } from '@/utils/constant';

const exportCasesAPI = new ExportcasesApi(new ApiConfiguration().configuration);

export const exportCases = async (
  printRequest: CasesSearchQuery,
  options?: AxiosRequestConfig,
): Promise<ExportCasesResponse> => {
  const {
    companyId,
    storeName,
    industryIds,
    prefectures,
    municipalities,
    occurrenceStartDate,
    occurrenceEndDate,
    dayOfWeek,
    caseId,
    caseType,
    totalPriceFrom,
    totalPriceTo,
    vehicleNumber,
    situation,
    numberOfPeople,
    gender,
    age,
    nationality,
    janCode,
    productName,
    freeComment,
    emlinxStatus,
    sortBy,
    sortOrder,
    createdStartDate,
    createdEndDate,
  } = printRequest;

  const currentDate = format(new Date(), DateFormat.fullDateISO8601WithOffset);

  const response = await exportCasesAPI.exportCases(
    companyId,
    storeName,
    industryIds,
    prefectures,
    municipalities,
    occurrenceStartDate,
    occurrenceEndDate,
    dayOfWeek,
    caseId,
    caseType,
    totalPriceFrom,
    totalPriceTo,
    vehicleNumber,
    situation,
    numberOfPeople,
    gender,
    age,
    nationality,
    janCode,
    productName,
    freeComment,
    emlinxStatus,
    sortBy,
    sortOrder,
    currentDate,
    createdStartDate,
    createdEndDate,
    options,
  );

  return response.data;
};
