import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App.tsx';
import './index.css';
import '@/lib/i18n/config';
import '@/lib/axios';
import 'react-toastify/dist/ReactToastify.css';
import '@material-design-icons/font';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
