import { Button, CardMedia, Grid, Theme, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import EmlinxLogo from '@/assets/emlinx_logo_color.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notFound: {
      height: '100vh',
      '@media (max-width: 600px)': {
        padding: '16px',
      },
      '& > *:not(:first-child)': {
        marginTop: '60px',
        '@media (max-width: 600px)': {
          marginTop: 'unset',
        },
      },
      '&__title': {
        fontSize: '128px',
        fontWeight: 'bold',
        '@media (max-width: 600px)': {
          fontSize: '100px',
        },
      },
      '&__subtitle': {
        fontSize: '57px',
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        '@media (max-width: 600px)': {
          fontSize: '40px',
        },
      },
      '&__message': {
        fontWeight: '500',
        textAlign: 'center',
        whiteSpace: 'pre-line',
        fontSize: '22px',
        '@media (max-width: 600px)': {
          fontSize: '16px',
          marginBottom: '16px',
        },
      },
    },
  }),
);

export const NotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      className={classes.notFound}
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid item container justifyContent="center">
        <CardMedia
          component="img"
          image={EmlinxLogo}
          alt="logo"
          style={{ maxWidth: '330px', maxHeight: '109px' }}
        />
      </Grid>

      <Grid item container justifyContent="center" alignItems="center">
        <div
          className={`${classes.notFound}__title`}
          style={{ marginRight: '16px' }}
        >
          {t('misc.notFound.title')}
        </div>
        <div className={`${classes.notFound}__subtitle`}>
          <p>{t('misc.notFound.subtitle')}</p>
        </div>
      </Grid>

      <Grid item container justifyContent="center">
        <Typography
          sx={{
            fontWeight: 'normal',
            textAlign: 'center',
            whiteSpace: 'pre-line',
            fontSize: '22px',
          }}
          className={`${classes.notFound}__message`}
        >
          {t('misc.notFound.message')}
        </Typography>
      </Grid>

      <Grid item container justifyContent="center" alignItems="center">
        <Button
          component={RouterLink}
          to="/"
          variant="contained"
          size="large"
          replace={true}
        >
          {t('common.button.backToTop')}
        </Button>
      </Grid>
    </Grid>
  );
};
