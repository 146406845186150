import { Configuration } from '@/bundles/model';
import { API_URL } from '@/config';

export class ApiConfiguration {
  public configuration: Configuration;

  public constructor() {
    this.configuration = new Configuration({
      basePath: API_URL,
    });
  }
}
