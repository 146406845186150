import { Enforcer } from 'casbin';
import { useEffect, useState } from 'react';

import { AuthorizeDomains, DEFAULT_AUTHORIZATION_ACTIONS } from '../constants';
import { useAuthorization } from '../hooks/useAuthorization';

/**
 * The request orders is defined in: api/authorization/model.conf
 * Request order: sub, obj, act, domain
 */
export type PermissionRequest = {
  subject: string;
  object: string;
  action: string;
  domain?: string;
};

/**
 * The enforce methods from casbin
 * Docs: https://github.com/casbin/node-casbin
 */
const POLICIES_CHECK_METHOD = {
  match: (options: PermissionRequest) => {
    const { subject, object, action, domain } = options;
    return (enforcer: Enforcer) =>
      enforcer.enforce(subject, object, action, domain);
  },

  matchAll: (permissions: PermissionRequest[]) => {
    return async (enforcer: Enforcer) => {
      const results = await enforcer.batchEnforce(
        permissions.map(
          ({ subject, object, action, domain = AuthorizeDomains.FE }) => [
            subject,
            object,
            action,
            domain,
          ],
        ),
      );

      // Check if all permissions are match
      const isMatchAll = results.every(result => result === true);
      return isMatchAll;
    };
  },

  matchAny: (permissions: PermissionRequest[]) => {
    return async (enforcer: Enforcer) => {
      const results = await enforcer.batchEnforce(
        permissions.map(
          ({ subject, object, action, domain = AuthorizeDomains.FE }) => [
            subject,
            object,
            action,
            domain,
          ],
        ),
      );

      // Check if at least one permissions is match
      const isMatchAny = results.some(result => result === true);
      return isMatchAny;
    };
  },
};

type AuthorizationProps = {
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
} & (
  | {
      accessEntity?: never;
      allowActions?: never;
      accessDomain?: never;
      policyCheck: (enforcer: Enforcer) => Promise<boolean>;
    }
  | {
      accessEntity: string;
      allowActions?: string[];
      accessDomain?: string;
      policyCheck?: never;
    }
);

const Authorization: React.FC<AuthorizationProps> = ({
  forbiddenFallback = null,
  children,
  accessEntity,
  allowActions = DEFAULT_AUTHORIZATION_ACTIONS,
  policyCheck,
}: AuthorizationProps) => {
  const { policies, checkAccess } = useAuthorization();
  const [canAccess, setCanAccess] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const fetchCheckPolicy = async (
    policyCheckCallback: (enforcer: Enforcer) => Promise<boolean>,
  ) => {
    try {
      if (!policies) {
        return false;
      }

      const res = await policyCheckCallback(policies);
      return res;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      let access = false;

      if (accessEntity) {
        access = await checkAccess(accessEntity, allowActions);
      }

      if (typeof policyCheck !== 'undefined') {
        access = await fetchCheckPolicy(policyCheck);
      }

      setCanAccess(access);
      setIsInitialized(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policies, accessEntity, allowActions, policyCheck]);

  if (!isInitialized) {
    return <></>;
  }

  return <>{canAccess ? children : forbiddenFallback}</>;
};

// eslint-disable-next-line react-refresh/only-export-components
export { Authorization, POLICIES_CHECK_METHOD };
