import { Grid, InputLabel } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Typography } from 'antd';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
  createStyles({
    fieldWrapper: {
      width: '100%',
      flexWrap: 'nowrap',
      '&__label': {
        color: '#000000',
        display: 'flex',
        flexDirection: 'row',
        '&--left': {
          paddingTop: '4px',
          marginRight: '4px',
        },
        '&--required': {
          '& .MuiFormLabel-asterisk': {
            color: 'red',
          },
        },
      },
      '&__subLabelPassword': {
        paddingBottom: '4px',
        color: 'red',
        fontSize: '12px',
      },
    },
  }),
);

type Position = 'left' | 'top';

type FieldWrapperProps = {
  /**
   * Identifier.
   */
  id?: string;
  /**
   * Label display text.
   */
  label?: React.ReactNode;
  /**
   * Position of label to use: none | left | top
   */
  labelPosition?: Position;
  /**
   * 	If true, the label is displayed as required and the input element is required.
   */
  required?: boolean;
  /**
   * 	The content of the component.
   */
  children: React.ReactNode;
  /**
   * The sub-label for password input
   */
  subLabelPassword?: string;
};

type FieldWrapperCommonProps = Omit<FieldWrapperProps, 'children'>;

const FieldWrapper: React.FC<FieldWrapperProps> = ({
  id,
  label,
  labelPosition,
  required,
  children,
  subLabelPassword,
}: FieldWrapperProps) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      direction={labelPosition === 'top' ? 'column' : 'row'}
      className={classes.fieldWrapper}
    >
      <Grid item>
        <InputLabel
          htmlFor={id}
          className={clsx(`${classes.fieldWrapper}__label`, {
            [`${classes.fieldWrapper}__label--left`]: labelPosition === 'left',
            [`${classes.fieldWrapper}__label--required`]: !!required,
          })}
          required={required}
        >
          {label}
        </InputLabel>
      </Grid>
      {subLabelPassword && (
        <Typography className={`${classes.fieldWrapper}__subLabelPassword`}>
          {subLabelPassword}
        </Typography>
      )}
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export type { FieldWrapperCommonProps, Position };
export { FieldWrapper };
