const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const NumberRegex = /^\d+$/;

const NumberRegexFullWidth = /^[0-9０-９]+$/;

const PasswordRegex: Readonly<{ [key: string]: RegExp }> = {
  HAS_NUMBER: /^(?=.*\d)/,
  HAS_LOWER_CASE: /^(?=.*[a-z])/,
  HAS_UPPER_CASE: /^(?=.*[A-Z])/,
  HAS_SPECIAL_CHARACTER: /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+)/,
  VALID_LENGTH: /^.{8,64}$/,
};

const PhoneNumberRegex: Readonly<{ [key: string]: RegExp }> = {
  VALID_CHARACTERS: /^[0-9-]+$/,
  VALID_LENGTH: /^.{10,13}$/,
  // Valid format is combine of valid characters and length
  VALID_FORMAT: /^[0-9-]{10,13}$/,
};

// Valid format: Katakana
const FuriganaRegex: RegExp = /^([ァ-ン\s]|ー)+$/;

// Valid format: English alphabet, Hiragana, Katakana, Kanji
const NameRegex: RegExp =
  /^[A-Za-zぁ-んァ-ンー一-龥０-９\d\s!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]+$/;

/**
 * Valid format:
 *  - 600-8216
 *  - 6008216
 */
const PostalCodeRegex = /[0-9]{3}-?[0-9]{4}?/;

/**
 * Valid format:
 *  - http://youtu.be/cCnrX1w5luM
 * Return array string:
 *  - Match 0: http://youtu.be/cCnrX1w5luM
 *  - Group 1: http://
 *  - Group 3: youtu.be
 *  .....
 *  - Group 6: cCnrX1w5luM
 */
const YoutubeUrlRegex =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|live\/|v\/)?)([\w-]+)(\S+)?$/;

export {
  EmailRegex,
  NumberRegex,
  NumberRegexFullWidth,
  PasswordRegex,
  PhoneNumberRegex,
  FuriganaRegex,
  NameRegex,
  PostalCodeRegex,
  YoutubeUrlRegex,
};
