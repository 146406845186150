import type { AxiosRequestConfig } from 'axios';

import { RefreshtokenApi, RefreshTokenResponse } from '@/bundles/model/';
import { ApiConfiguration } from '@/lib/swagger';

const refreshtokenAPI = new RefreshtokenApi(
  new ApiConfiguration().configuration,
);

export const refreshToken = async (
  options?: AxiosRequestConfig,
): Promise<RefreshTokenResponse> => {
  const response = await refreshtokenAPI.postRefreshToken(options);

  return response.data;
};
