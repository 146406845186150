import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { ProjectsSearchQuery } from '../types';

import { Project } from '@/bundles/model/models/project';
import { PaginationModel } from '@/components/Table';
import { IS_PRODUCTION_MODE } from '@/config';
import { ExtractState, Params } from '@/types';

interface ProjectsStore {
  projects: Project[];
  searchQuery: ProjectsSearchQuery;
  pagination: PaginationModel;
  actions: {
    updateProjects: (projects: Project[]) => void;
    updatePagination: (pagination: PaginationModel) => void;
    updateSearchQuery: (searchQuery: ProjectsSearchQuery) => void;
    resetState: () => void;
  };
}

const defaultPagination = {
  page: 1,
  pageSize: 10,
};

const projectsStore = createStore<ProjectsStore>()(
  devtools(
    set => ({
      projects: [],
      searchQuery: {},
      pagination: defaultPagination,
      actions: {
        updateProjects: (projects: Project[]) => {
          set({
            projects,
          });
        },
        updatePagination: (pagination: PaginationModel) => {
          set({
            pagination,
          });
        },
        updateSearchQuery: (searchQuery: ProjectsSearchQuery) => {
          set({
            searchQuery,
          });
        },
        resetState: () => {
          set({
            projects: [],
            searchQuery: {},
            pagination: defaultPagination,
          });
        },
      },
    }),
    {
      name: 'projects',
      enable: !IS_PRODUCTION_MODE,
    },
  ),
);

const useProjectsStore = <U>(
  selector: Params<U, typeof projectsStore>[1],
  equalityFn?: Params<U, typeof projectsStore>[2],
) => {
  return useStoreWithEqualityFn(projectsStore, selector, equalityFn);
};

// Selectors
const projectsSelector = (state: ExtractState<typeof projectsStore>) =>
  state.projects;

const paginationSelector = (state: ExtractState<typeof projectsStore>) =>
  state.pagination;

const searchQuerySelector = (state: ExtractState<typeof projectsStore>) =>
  state.searchQuery;

const actionsSelector = (state: ExtractState<typeof projectsStore>) =>
  state.actions;

// Getters
const getProjects = () => projectsSelector(projectsStore.getState());

const getPagination = () => paginationSelector(projectsStore.getState());

const getSearchQuery = () => searchQuerySelector(projectsStore.getState());

const getActions = () => actionsSelector(projectsStore.getState());

// Hooks
const useProjects = () => useProjectsStore(projectsSelector, shallow);

const usePagination = () => useProjectsStore(paginationSelector, shallow);

export {
  defaultPagination,

  // Getters
  getActions,
  getProjects,
  getPagination,
  getSearchQuery,

  // Hooks
  useProjects,
  usePagination,
};
