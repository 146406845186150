import { getActions as getActionsCases } from "@/features/cases/stores/cases";
import { getActions as getActionsCompany } from "@/features/companies/stores/companies";
import { getActions as getActionsDashboard } from "@/features/dashboard/stores/dashboard";
import { getActions as getActionsInDustry } from "@/features/industry/stores/industry";
import { getActions as getActionsProject } from "@/features/projects/stores/projects";
import { getActions as getActionsStore } from "@/features/stores/stores/stores";

export const resetState = (path?: string) => {
  if(!path) return;
  if (path.includes('cases') && window.location.pathname !== path) {
    const { resetState: resetStateCases } = getActionsCases();
    resetStateCases();
  }
  if (path.includes('companies') && window.location.pathname !== path) {
    const { resetState: resetStateCompany } = getActionsCompany();
    resetStateCompany();
  }
  if (path.includes('industry') && window.location.pathname !== path) {
    const { resetState: resetStateInDustry } = getActionsInDustry();
    resetStateInDustry();
  }
  if (path.includes('projects') && window.location.pathname !== path) {
    const { resetState: resetStateProject } = getActionsProject();
    resetStateProject();
  }
  if (path.includes('stores') && window.location.pathname !== path) {
    const { resetState: resetStateStore } = getActionsStore();
    resetStateStore();
  }
  if (path.includes('dashboard') && window.location.pathname !== path) {
    const { resetState: resetStateDashboard } = getActionsDashboard();
    resetStateDashboard();
  }
}