import { Button, Divider, Paper, Grid, Typography, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { ChangePasswordValues } from '../types';
import { fakeChangePassword } from '../utils/mock';

import { Form } from '@/components/Form';
import { InputText } from '@/components/InputText';
import { useAuth } from '@/providers/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      '& > *:not(:first-child)': {
        marginTop: '48px',
      },
      '&__subtitle': {
        marginTop: '8px',
        fontWeight: 'normal',
      },
      '@media (min-width: 600px)': {
        marginTop: theme.spacing(14),
      },
    },
    paper: {
      padding: '20px',
      maxWidth: '400px',
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      '& > *:not(:first-child)': {
        marginTop: '16px',
      },
      '&__title': {
        fontWeight: 'normal',
        textAlign: 'center',
      },
      '&__subtitle': {
        color: '#686868',
        textAlign: 'center',
      },
      '&__link': {
        color: '#4695D9',
        width: 'fit-content',
      },
      '&__input': {
        '& .MuiFormHelperText-root': {
          whiteSpace: 'pre-line',
        },
      },
    },
  }),
);

const ALLOW_SPECIAL_CHARACTER = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';

const newPasswordSchema = z
  .string()
  .min(1, 'auth.passwordRecover.reset.validate.newPassword.isRequired')
  .regex(
    /\d/,
    'auth.passwordRecover.reset.validate.newPassword.shouldHaveNumber',
  )
  .regex(
    /[a-z]/,
    'auth.passwordRecover.reset.validate.newPassword.shouldHaveLowerLetter',
  )
  .regex(
    /[A-Z]/,
    'auth.passwordRecover.reset.validate.newPassword.shouldHaveUpperLetter',
  )
  .regex(
    /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+/,
    'auth.passwordRecover.reset.validate.newPassword.shouldHaveSpecialCharacter',
  )
  .refine(
    value => value.length >= 8 && value.length <= 64,
    'auth.passwordRecover.reset.validate.newPassword.validLength',
  );

const formSchema = z
  .object({
    currentPassword: z
      .string()
      .min(1, 'auth.changePassword.validate.currentPasswordIsRequired'),
    newPassword: newPasswordSchema,
    confirmNewPassword: z
      .string()
      .min(1, 'auth.passwordRecover.reset.validate.confirmPassword.isRequired'),
  })
  .refine(data => data.newPassword === data.confirmNewPassword, {
    message: 'auth.passwordRecover.reset.validate.confirmPassword.isInValid',
    path: ['confirmNewPassword'],
  });

type ChangePasswordFormProps = {
  onSuccess: () => void;
};

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  onSuccess,
}: ChangePasswordFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { authUser } = useAuth();

  const submitRequest = async (formValues: ChangePasswordValues) => {
    try {
      await fakeChangePassword(formValues);
      onSuccess();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Paper className={classes.paper} elevation={5}>
        <Form<ChangePasswordValues, typeof formSchema>
          className={classes.form}
          schema={formSchema}
          onSubmit={submitRequest}
          options={{
            mode: 'all',
          }}
        >
          {({ register, formState, getValues, clearErrors, trigger }) => (
            <>
              <Typography variant="h2" className={`${classes.form}__title`}>
                {t('auth.changePassword.title')}
              </Typography>
              <Grid className={`${classes.form}__subtitle`}>
                <Typography variant="body2">
                  {t('auth.changePassword.emailNotification', {
                    email: authUser ? authUser.email : '',
                  })}
                </Typography>
                <Typography variant="body2">
                  {t('auth.changePassword.reminder')}
                </Typography>
              </Grid>
              <Divider className={`${classes.form}__divider`} />
              <InputText
                id="current-password"
                label={t('auth.changePassword.input.currentPassword')}
                placeholder={t('auth.changePassword.input.currentPassword')}
                type="password"
                fullWidth
                error={!!formState.errors.currentPassword}
                helperText={t(formState.errors.currentPassword?.message ?? '')}
                registrationForm={register('currentPassword')}
              />
              <InputText
                id="new-password"
                label={t('auth.passwordRecover.reset.input.newPassword')}
                placeholder={t('auth.passwordRecover.reset.input.newPassword')}
                type="password"
                fullWidth
                error={!!formState.errors.newPassword}
                helperText={t(formState.errors.newPassword?.message ?? '', {
                  characters: ALLOW_SPECIAL_CHARACTER,
                })}
                registrationForm={register('newPassword', {
                  onChange: event => {
                    const confirmNewPassword = getValues('confirmNewPassword');
                    if (event.target.value === confirmNewPassword) {
                      clearErrors('confirmNewPassword');
                    } else if (confirmNewPassword) {
                      trigger('confirmNewPassword');
                    }
                  },
                })}
                className={`${classes.form}__input`}
              />
              <InputText
                id="confirm-new-password"
                label={t('auth.passwordRecover.reset.input.confirmNewPassword')}
                placeholder={t(
                  'auth.passwordRecover.reset.input.confirmNewPassword',
                )}
                type="password"
                fullWidth
                error={!!formState.errors.confirmNewPassword}
                helperText={t(
                  formState.errors.confirmNewPassword?.message ?? '',
                )}
                registrationForm={register('confirmNewPassword')}
                className={`${classes.form}__input`}
              />
              <Button
                variant="contained"
                size="medium"
                type="submit"
                disabled={Object.keys(formState.errors).length > 0}
              >
                {t('common.button.change')}
              </Button>
            </>
          )}
        </Form>
      </Paper>
    </Grid>
  );
};
