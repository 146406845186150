const routes = {
  maintain: {
    index: '/',
    wildcard: '/*',
  },
  auth: {
    login: '/login',
    changePassword: '/change-password',
    forgotPassword: {
      base: '/forgot-password',
      request: '/request',
      instruction: '/instruction',
      reset: '/:token',
      status: {
        success: '/success',
        error: '/error',
      },
      wildcard: '/*',
    },
  },
  industry: {
    index: '/industry',
    wildcard: '/industry/*',
  },
  companies: {
    index: '/companies',
    wildcard: '/companies/*',
    record: {
      new: '/new',
      detail: '/:companyId',
      import: '/import',
      edit: '/:companyId/edit',
    },
  },
  misc: {
    notFound: '/not-found',
    forbidden: '/forbidden',
  },
  administrators: {
    index: '/administrators',
    wildcard: '/administrators/*',
    record: {
      new: '/new',
      detail: '/:adminId',
      edit: '/:adminId/edit',
    },
  },
  projects: {
    index: '/projects',
    wildcard: '/projects/*',
    record: {
      new: '/new',
      detail: '/:projectId',
      edit: '/:projectId/edit',
    },
  },
  dashboard: {
    index: '/dashboard',
    wildcard: '/dashboard/*',
  },
  line: {
    index: '/line',
    wildcard: '/line/*',
  },
  stores: {
    index: '/stores',
    wildcard: '/stores/*',
    record: {
      new: '/new',
      detail: '/:storeId',
      edit: '/:storeId/edit',
    },
    import: '/import',
  },
  cases: {
    index: '/cases',
    wildcard: '/cases/*',
    record: {
      'approval-pending': '/approval-pending',
      all: '/all',
      editing: '/editing',
      new: '/new',
      detail: '/:caseId',
      edit: '/:caseId/edit',
      import: '/import',
    },
  },
  loginInfo: {
    index: '/login-info',
    wildcard: '/login-info/*',
  },
  faq: {
    index: '/faq',
    wildcard: '/faq/*',
  },
  guidelines: {
    index: '/guidelines',
    wildcard: '/guidelines/*',
    record: {
      new: '/new',
      detail: '/:guidelineId',
      edit: '/:guidelineId/edit',
    },
  },
  documents: {
    index: '/documents',
    wildcard: '/documents/*',
  },
  videos: {
    index: '/videos',
    wildcard: '/videos/*',
  },
};

export { routes };
