/* tslint:disable */
/* eslint-disable */
/**
 * EMLINX API specification
 * This is API specification for EMLINX project.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { UpdateCaseRequest } from '../models';
/**
 * UpdatecaseApi - axios parameter creator
 * @export
 */
export const UpdatecaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Update case
         * @summary PUT Case
         * @param {number} id case\&#39;s ID
         * @param {UpdateCaseRequest} [updateCaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCase: async (id: number, updateCaseRequest?: UpdateCaseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putCase', 'id', id)
            const localVarPath = `/cases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpdatecaseApi - functional programming interface
 * @export
 */
export const UpdatecaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpdatecaseApiAxiosParamCreator(configuration)
    return {
        /**
         * Update case
         * @summary PUT Case
         * @param {number} id case\&#39;s ID
         * @param {UpdateCaseRequest} [updateCaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCase(id: number, updateCaseRequest?: UpdateCaseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCase(id, updateCaseRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UpdatecaseApi.putCase']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UpdatecaseApi - factory interface
 * @export
 */
export const UpdatecaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpdatecaseApiFp(configuration)
    return {
        /**
         * Update case
         * @summary PUT Case
         * @param {number} id case\&#39;s ID
         * @param {UpdateCaseRequest} [updateCaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCase(id: number, updateCaseRequest?: UpdateCaseRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putCase(id, updateCaseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpdatecaseApi - object-oriented interface
 * @export
 * @class UpdatecaseApi
 * @extends {BaseAPI}
 */
export class UpdatecaseApi extends BaseAPI {
    /**
     * Update case
     * @summary PUT Case
     * @param {number} id case\&#39;s ID
     * @param {UpdateCaseRequest} [updateCaseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdatecaseApi
     */
    public putCase(id: number, updateCaseRequest?: UpdateCaseRequest, options?: RawAxiosRequestConfig) {
        return UpdatecaseApiFp(this.configuration).putCase(id, updateCaseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

