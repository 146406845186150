import type { AxiosRequestConfig } from 'axios';

import { CreateCaseRequest, CreatecaseApi } from '@/bundles/model';
import { ApiConfiguration } from '@/lib/swagger';

const createCaseAPI = new CreatecaseApi(new ApiConfiguration().configuration);

export const createCase = async (
  createCaseRequest: CreateCaseRequest,
  options?: AxiosRequestConfig,
): Promise<boolean> => {
  const response = await createCaseAPI.postCases(createCaseRequest, options);

  if (response.status !== 201) {
    throw new Error('createCase failed');
  }

  return true;
};
