import { List, Typography, Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import MenuItem from './MenuItem';

import { APP_BAR_HEIGHT, menuLinks } from '.';

import { useMenu } from '@/features/menu/stores/menuStore';
import { useDebouncedEffect } from '@/hooks/useDebouncedEffect';
import { useLocalStorage } from '@/hooks/useLocalStorage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapped: {
      height: `calc(100% - ${APP_BAR_HEIGHT}px)`,
      padding: '8px 8px',
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    menuList: {
      '& > *:not(:last-child)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      },

      [theme.breakpoints.down('sm')]: {
        '& > *': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
      },
    },
    wrapMenuLink: {
      padding: '48px 0',

      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    menuLink: {
      paddingBottom: '32px',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',

      [theme.breakpoints.down('sm')]: {
        padding: '16px 0',
        paddingBottom: '16px',
      },

      '&__link': {
        width: '100%',
        fontSize: '12px',

        '& a': {
          textDecoration: 'none',

          '&:visited': {
            color: 'blue',
          },
        },
      },
    },
  }),
);

const CASES_INDEX_ROUTE_PREFIX = ['all', 'approval-pending', 'editing'];

const CASES_ALL_ROUTE = '/cases/all';

const CASES_INDEX = 'cases';

interface MenuItemProps {
  open: boolean;
}

const UserNavigation: React.FC<MenuItemProps> = ({ open }) => {
  const classes = useStyles();
  const menu = useMenu();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { storedValue: activeItem, setValue: updateActiveItem } =
    useLocalStorage('menu_active_route', pathname);

  /**
   * * Trigger update storage when user enter link from URL or location change
   * * Delay 500 milliseconds to better user-experienced
   */
  useDebouncedEffect(
    () => {
      const routeSplit = pathname.split('/').filter(char => Boolean(char));
      // Check if URL is cases route or not
      if (!pathname.includes(CASES_INDEX)) {
        const parentRoute = routeSplit?.[0];
        updateActiveItem(parentRoute ? `/${parentRoute}` : pathname);
        return;
      }

      // If URL is cases
      const isCaseIndexRoute = CASES_INDEX_ROUTE_PREFIX.includes(
        routeSplit[routeSplit.length - 1],
      );

      if (isCaseIndexRoute) {
        updateActiveItem(pathname);
        return;
      }

      /**
       * Check if value from storage is cases index routes or not
       * If correct: do nothing
       * else: update storage with default cases route
       */
      const splitLastActiveItem =
        activeItem.split('/').filter(char => Boolean(char)) ?? [];

      const lastActiveItem =
        splitLastActiveItem[splitLastActiveItem.length - 1];

      const isLastItemIsCasesIndexRoute =
        activeItem.includes(CASES_INDEX) &&
        CASES_INDEX_ROUTE_PREFIX.includes(lastActiveItem);

      if (isLastItemIsCasesIndexRoute && routeSplit.length > 1) {
        return;
      }

      updateActiveItem(CASES_ALL_ROUTE);
    },
    [pathname],
    500,
  );

  const renderMenuLinks = () => {
    return (
      <List className={classes.menuLink}>
        {menuLinks.map(item => (
          <Typography key={item.key} className={`${classes.menuLink}__link`}>
            {t(`menuLink.${item.key}`)}
            <Link to={item.url} target="_blank">
              {t('menuLink.here')}
            </Link>
          </Typography>
        ))}
      </List>
    );
  };

  return (
    <List className={classes.wrapped}>
      <Grid container className={classes.menuList}>
        {menu
          .filter(m => m.isActive)
          .map(item => (
            <MenuItem
              {...item}
              key={item.id}
              open={open}
              updateActiveItem={updateActiveItem}
              activeItem={activeItem}
            />
          ))}
      </Grid>
      <Grid container className={classes.wrapMenuLink}>
        {renderMenuLinks()}
      </Grid>
    </List>
  );
};

export default UserNavigation;
