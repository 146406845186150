import { Grid, InputLabel } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker/interface';

import { jaJP } from './locale';

const { RangePicker } = DatePicker;

const useStyles = makeStyles(() =>
  createStyles({
    inputDateRange: {
      '& .ant-picker-outlined': {
        borderColor: '#C8C8C8',
        borderRadius: '4px',
        height: '40px',
        width: '100%',
      },
      '&& .ant-picker-input > input': {
        fontSize: '16px',
        fontFamily: 'initial',
        cursor: 'pointer',
      },
      '&& label': {
        color: '#000000',
      },
    },
  }),
);

type Props = {
  id?: string;
  label: string;
  name: string;
  onChange: (dateString: [string, string]) => void;
} & RangePickerProps;

export const InputDateRange: React.FC<Props> = ({
  id,
  name,
  label,
  onChange,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      direction={'column'}
      className={classes.inputDateRange}
    >
      <Grid item>
        <InputLabel htmlFor={'selectDateRange'}>{label}</InputLabel>
      </Grid>
      <Grid item>
        <RangePicker
          {...rest}
          id={id}
          name={name}
          locale={jaJP}
          format={'YYYY-MM-DD'}
          onChange={(_, dateString) => {
            onChange(dateString);
          }}
        />
      </Grid>
    </Grid>
  );
};
