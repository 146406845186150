import { createTheme } from '@mui/material/styles';

// Create your theme with palette customizations
const paletteTheme = createTheme({
  palette: {
    primary: {
      main: '#2196F3',
      dark: '#3281C5',
      light: '#5AA9EF',
    },
    secondary: {
      main: '#FFFFFF',
      dark: '#3281C5',
      light: '#5AA9EF',
    },
    text: {
      primary: '#000000',
      secondary: '#686868',
    },
    error: {
      main: '#F44336',
    },
    success: {
      main: '#4CAF50',
    },
    warning: {
      main: '#FF9800',
    },
    info: {
      main: '#4695D9',
    },
  },
});

// Create your theme with all customizations
export const theme = createTheme({
  palette: paletteTheme.palette,
  typography: {
    // default font size
    fontSize: 14,
    fontFamily: ['Roboto'].join(','),
    h1: {
      fontSize: '24px',
      lineHeight: '35px',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '18px',
      lineHeight: '26px',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '16px',
      lineHeight: '23px',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '16px',
      lineHeight: '26px',
      fontWeight: 'normal',
    },
    body2: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 'normal',
    },
    button: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
      textAlign: 'center',
    },
    caption: {
      fontSize: '12px',
      lineHeight: '17px',
      fontWeight: 'normal',
    },
  },
  mixins: {
    toolbar: {
      minHeight: '48px',
      '@media (min-width: 600px)': {
        minHeight: '48px',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '48px',
          fontSize: '24px',
          fontWeight: 'bold',
        },
        colorDefault: {
          color: '#4EADFE',
          backgroundColor: '#FFFFFF',
        },
        colorPrimary: {
          color: '#4EADFE',
          backgroundColor: '#FFFFFF',
          '&& svg': {
            color: '#231F20',
          },
          '&& span': {
            color: '#FFFFFF',
          },
        },
        colorSecondary: {
          color: '#4EADFE',
          backgroundColor: '#EEEEEE',
          '&& svg': {
            color: '#231F20',
          },
          '&& span': {
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          backgroundColor: '#2196F3',
          color: '#FFFFFF',
        },
        containedSuccess: {
          backgroundColor: '#2E7D32',
          color: '#FFFFFF',
        },
        containedWarning: {
          color: '#FFFFFF',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minHeight: '32px',
          '& input': {
            height: '32px',
            padding: '0 14px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          minHeight: '32px',
          '&$multiline': {
            padding: '4px 14px',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          minHeight: '32px',
          '&$multiline': {
            padding: '4px 14px',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          minHeight: '32px',
          '&$multiline': {
            padding: '4px 14px',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          '& span': {
            whiteSpace: 'normal',
            wordBreak: 'break-all',
            marginBottom: '2px',
            textAlign: 'left',
          },
        },
        sizeSmall: {
          height: 'auto',
          width: 'auto',
        },
      },
    },
  },
});
