import type { AxiosRequestConfig } from 'axios';

import { DeletecaseApi } from '@/bundles/model/';
import { ApiConfiguration } from '@/lib/swagger';

const deleteCaseApi = new DeletecaseApi(new ApiConfiguration().configuration);

export const deleteCase = async (
  id?: number,
  options?: AxiosRequestConfig,
): Promise<void> => {
  if (!id) {
    throw new Error('Missing id');
  }

  const response = await deleteCaseApi.deleteCase(id, options);

  return response.data;
};
