export enum MasterItems {
  CaseType = 'CASE_TYPE',
  Situation = 'SITUATION',
  PoliceReport = 'POLICE_REPORT',
  DamageReport = 'DAMAGE_REPORT',
  Gender = 'THEFT_GENDER',
  Age = 'THEFT_AGE',
  Height = 'THEFT_HEIGHT',
  Build = 'THEFT_PHYSIQUE',
  Nationality = 'THEFT_NATIONALITY',
  Features = 'VEHICLE_FEATURES',
  Colors = 'VEHICLE_COLOR',
  Accessories = 'THEFT_CHARACTERISTIC',
  DayOfWeek = 'DAY_OF_WEEK',
}

export enum ProductOptions {
  JanCode = 'janCode',
  ProductName = 'productName',
}

export enum CaseStatus {
  All = 'ALL',
  Completed = 'COMPLETED',
  ApprovalPending = 'APPROVAL_PENDING',
  Temporary = 'TEMPORARY',
  Editing = 'EDITING',
}

export const CASE_FILTER_CASE_TYPE = [
  '商品すり替え',
  '万引下見',
  'セルフレジ不正',
  'カスハラ・クレーマー',
];
