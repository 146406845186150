import { TextField, InputAdornment } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { createStyles, makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import clsx from 'clsx';
import React, { useState, useRef, useCallback, useMemo } from 'react';

import { FieldWrapper } from '../FieldWrapper';

import { InputNumberProps, StepDirection } from './type';

import {
  isNumber,
  toPrecision,
  countDecimalPlaces,
  fullWidthToHalfWidth,
} from '@/utils';

const useStyles = makeStyles(() =>
  createStyles({
    inputText: {
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 'unset',
      },
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: 'unset',
      },
      '& .MuiIconButton-root': {
        padding: '4px',
        marginRight: 'unset',
      },
      '& .MuiFormHelperText-root': {
        marginRight: 'unset',
        marginLeft: 'unset',
      },
    },
  }),
);

const StyledStepperButton = styled('button')(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 20px;
  height: 10px;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 0;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
  padding: 4px;

    &.increment {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid;
      border-bottom: 0;
      border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
      color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};

      &:hover {
        cursor: pointer;
        color: #FFF;
        background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
        border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
      }
    }

    &.decrement {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid;
      border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
      color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};

      &:hover {
        cursor: pointer;
        color: #FFF;
        background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
        border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
      }
  }
  `,
);

const FLOATING_POINT_REGEX = /^[Ee0-9０-９+\-.]*$/;

const NON_ALPHANUMERIC_DOT_HYPHEN_REGEX = /[^\w.-]+/g;

// Utils function
const isNumberInput = (character: string) => {
  return FLOATING_POINT_REGEX.test(character);
};

const safeParse = (value: string | number) => {
  return parseFloat(
    value.toString().replace(NON_ALPHANUMERIC_DOT_HYPHEN_REGEX, ''),
  );
};

const getDecimalPlaces = (value: number, step: number) => {
  return Math.max(countDecimalPlaces(step), countDecimalPlaces(value));
};

const cast = (value: string | number, step: number, precision?: number) => {
  const parsedValue = safeParse(value);
  if (Number.isNaN(parsedValue)) return undefined;
  const decimalPlaces = getDecimalPlaces(parsedValue, step);
  return toPrecision(parsedValue, precision ?? decimalPlaces);
};

const isValidNumericKeyboardEvent = (
  event: React.KeyboardEvent,
  isValid: (key: string) => boolean,
) => {
  if (event.key === null) return true;

  const isModifierKey = event.ctrlKey || event.altKey || event.metaKey;
  const isSingleCharacterKey = event.key.length === 1;
  if (!isSingleCharacterKey || isModifierKey) return true;

  return isValid(event.key);
};

// Main component
export const InputNumber: React.FC<InputNumberProps> = ({
  value: valueProp,
  defaultValue: defaultValueProp,
  max = Number.MAX_SAFE_INTEGER,
  min = Number.MIN_SAFE_INTEGER,
  label,
  labelPosition,
  showButtons,
  step = 1,
  onChange: onChangeProp,
  clampValueOnBlur = true,
  precision: precisionProp,
  format: formatValue,
  isValidCharacter: isValidCharacterProp,
  ...restProps
}: InputNumberProps) => {
  const classes = useStyles();

  const isFocused = useRef<boolean>(false);

  const [valueState, setValue] = useState<string | number>(() => {
    if (defaultValueProp == null) return '';
    return cast(defaultValueProp, step, precisionProp) ?? '';
  });

  const isControlled = typeof valueProp !== 'undefined';

  const value = isControlled ? valueProp : valueState;
  const valueAsNumber = safeParse(value);

  const isAtMax = valueAsNumber === max;
  const isAtMin = valueAsNumber === min;

  const decimalPlaces = getDecimalPlaces(safeParse(value), step);

  const precision = precisionProp ?? decimalPlaces;

  const isValidCharacter = useMemo(() => {
    return isValidCharacterProp ?? isNumberInput;
  }, [isValidCharacterProp]);

  const sanitize = useCallback(
    (value: string) => {
      return value.split('').filter(isValidCharacter).join('');
    },
    [isValidCharacter],
  );

  const format = useCallback(
    (value: string | number) => {
      return (formatValue?.(value) ?? value).toString();
    },
    [formatValue],
  );

  const validateValueByLimit = useCallback(
    (rawValue: number) => {
      if (rawValue > max) {
        rawValue = max;
      }
      if (rawValue < min) {
        rawValue = min;
      }

      return rawValue;
    },
    [max, min],
  );

  const update = useCallback(
    (next: string | number) => {
      if (next === value) return;
      if (!isControlled) {
        setValue(next.toString());
      }
      onChangeProp?.(safeParse(next), next.toString());
    },
    [onChangeProp, isControlled, value],
  );

  const castValue = useCallback(
    (value: string | number) => {
      const nextValue = cast(value, step, precision) ?? min;
      update(nextValue);
    },
    [precision, step, update, min],
  );

  const validateOnBlur = useCallback(() => {
    if (value === '') return;

    let validVal: string | number = fullWidthToHalfWidth(String(value));

    if (clampValueOnBlur) {
      validVal = validateValueByLimit(safeParse(validVal));
      castValue(validVal);
      return;
    }
    update(validVal);
  }, [castValue, clampValueOnBlur, update, validateValueByLimit, value]);

  const stepValue = useCallback(
    (value: number | null, direction: StepDirection) => {
      if (isNumber(value)) {
        return {
          up: value + step,
          down: value - step,
        }[direction];
      }

      return {
        up: safeParse(step),
        down: safeParse(-step),
      }[direction];
    },
    [step],
  );

  const handleStepChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    direction: StepDirection,
  ) => {
    e.preventDefault();

    const newValue = stepValue(safeParse(value), direction);
    const validValue = validateValueByLimit(newValue);

    update(validValue);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    update(sanitize(e.target.value));
  };

  const onBlurInput = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    isFocused.current = true;

    validateOnBlur();

    restProps?.onBlur?.(e);
  };

  const onFocusInput = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    isFocused.current = true;

    restProps?.onFocus?.(e);
  };

  const onKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isValidNumericKeyboardEvent(e, isValidCharacter)) {
      e.preventDefault();
    }

    restProps?.onKeyDown?.(e);
  };

  const renderInputEndAdornment = () => {
    if (!showButtons) {
      return restProps?.InputProps?.endAdornment ?? undefined;
    }

    return (
      <InputAdornment position="end">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <StyledStepperButton
            className="increment"
            aria-label="Increment number"
            onClick={e => handleStepChange(e, 'up')}
            disabled={isAtMax}
          >
            ▴
          </StyledStepperButton>
          <StyledStepperButton
            className="decrement"
            aria-label="Decrement number"
            onClick={e => handleStepChange(e, 'down')}
            disabled={isAtMin}
          >
            ▾
          </StyledStepperButton>
        </div>
        {restProps?.InputProps?.endAdornment}
      </InputAdornment>
    );
  };

  return (
    <FieldWrapper
      id={restProps.id}
      label={label}
      labelPosition={labelPosition}
      required={restProps.required}
    >
      <TextField
        {...restProps}
        value={format(value)}
        type="text"
        inputMode="numeric"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        className={clsx(classes.inputText, restProps.className)}
        onChange={onChangeInput}
        onBlur={onBlurInput}
        onFocus={onFocusInput}
        onKeyDown={onKeyDownInput}
        InputProps={{
          ...restProps.InputProps,
          endAdornment: renderInputEndAdornment(),
        }}
      />
    </FieldWrapper>
  );
};

InputNumber.defaultProps = {
  labelPosition: 'top',
  type: 'text',
  showButtons: false,
};
