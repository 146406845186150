import { useCallback } from 'react';

import { AuthorizeDomains, DEFAULT_AUTHORIZATION_ACTIONS } from '../constants';

import { useAuth } from '@/providers/auth';

export const useAuthorization = () => {
  const { authUser, policies } = useAuth();

  const checkAccess = useCallback(
    async (
      accessEntity: string,
      allowActions: string[] = DEFAULT_AUTHORIZATION_ACTIONS,
      accessDomain: string = AuthorizeDomains.FE,
    ) => {
      if (!authUser?.roles || !policies) {
        return false;
      }

      try {
        const requestAccess = authUser.roles.flatMap(role =>
          allowActions.map(action => [
            `role:${role}`,
            accessEntity,
            action,
            accessDomain,
          ]),
        );

        const results = await policies.batchEnforce(requestAccess);
        return results.some(result => result === true);
      } catch (error) {
        console.error('User does not have permission', error);
        return false;
      }
    },
    [authUser, policies],
  );

  return {
    policies,
    checkAccess,
  };
};
