import type { AxiosRequestConfig } from 'axios';

import { UpdateCaseRequest, UpdatecaseApi } from '@/bundles/model';
import { ApiConfiguration } from '@/lib/swagger';

const updateCaseAPI = new UpdatecaseApi(new ApiConfiguration().configuration);

export const updateCase = async (
  id: number,
  updateCaseRequest: UpdateCaseRequest,
  options?: AxiosRequestConfig,
): Promise<boolean> => {
  const response = await updateCaseAPI.putCase(id, updateCaseRequest, options);
  if (response.status !== 200) {
    throw new Error('update case failed');
  }
  return true;
};
