import { useRef, useEffect } from 'react';

export const useOnMount = (func: () => void) => {
  const funcRef = useRef(func);

  funcRef.current = func;

  useEffect(() => {
    funcRef.current();
  }, []);
};
