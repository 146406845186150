import {
  Button,
  CircularProgress,
  Divider,
  Paper,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import {
  validateForgotPasswordToken,
  resetPasswordToken,
} from '../api/forgotPasswordToken';
import { ResetPasswordValues } from '../types';

import { Form } from '@/components/Form';
import { InputText } from '@/components/InputText';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: '20px',
      maxWidth: '400px',
      width: '100%',
    },
    resetForm: {
      display: 'flex',
      flexDirection: 'column',
      '& > *:not(:first-child)': {
        marginTop: '16px',
      },
      '&__title': {
        fontWeight: 'normal',
        textAlign: 'center',
      },
      '&__link': {
        color: '#4695D9',
        width: 'fit-content',
      },
      '&__input': {
        '& .MuiFormHelperText-root': {
          whiteSpace: 'pre-line',
        },
      },
    },
  }),
);

const ALLOW_SPECIAL_CHARACTER = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';

const newPasswordSchema = z
  .string()
  .min(1, 'auth.passwordRecover.reset.validate.newPassword.isRequired')
  .regex(
    /\d/,
    'auth.passwordRecover.reset.validate.newPassword.shouldHaveNumber',
  )
  .regex(
    /[a-z]/,
    'auth.passwordRecover.reset.validate.newPassword.shouldHaveLowerLetter',
  )
  .regex(
    /[A-Z]/,
    'auth.passwordRecover.reset.validate.newPassword.shouldHaveUpperLetter',
  )
  .regex(
    /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+/,
    'auth.passwordRecover.reset.validate.newPassword.shouldHaveSpecialCharacter',
  )
  .refine(
    value => value.length >= 8 && value.length <= 64,
    'auth.passwordRecover.reset.validate.newPassword.validLength',
  );

const formSchema = z
  .object({
    newPassword: newPasswordSchema,
    confirmNewPassword: z
      .string()
      .min(1, 'auth.passwordRecover.reset.validate.confirmPassword.isRequired'),
  })
  .refine(data => data.newPassword === data.confirmNewPassword, {
    message: 'auth.passwordRecover.reset.validate.confirmPassword.isInValid',
    path: ['confirmNewPassword'],
  });

type ResetPasswordFormProps = {
  onSuccess: () => void;
  onError: () => void;
};

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  onSuccess,
  onError,
}: ResetPasswordFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();

  const [isValidToken, setValidToken] = useState<string | undefined>();

  const fetchValidateToken = async (token: string) => {
    try {
      await validateForgotPasswordToken(token);
      return token;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const submitRequest = async (formValues: ResetPasswordValues) => {
    try {
      await resetPasswordToken(token!, formValues);
      onSuccess();
    } catch (error) {
      console.error(error);
      onError();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        onError();
        return;
      }

      const validToken = await fetchValidateToken(token);
      if (!validToken) {
        onError();
        return;
      }

      setValidToken(token);
    };

    fetchData();
  }, [token, navigate, onError]);

  return (
    <Paper className={classes.paper} elevation={5}>
      {!isValidToken ? (
        <Grid item container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Form<ResetPasswordValues, typeof formSchema>
          className={classes.resetForm}
          onSubmit={submitRequest}
          schema={formSchema}
          options={{
            mode: 'all',
          }}
        >
          {({ register, formState, getValues, clearErrors, trigger }) => (
            <>
              <Typography
                variant="h2"
                className={`${classes.resetForm}__title`}
              >
                {t('auth.passwordRecover.title.resetPassword')}
              </Typography>
              <Divider className={`${classes.resetForm}__divider`} />
              <InputText
                id="new-password"
                label={t('auth.passwordRecover.reset.input.newPassword')}
                placeholder={t('auth.passwordRecover.reset.input.newPassword')}
                type="password"
                fullWidth
                className={`${classes.resetForm}__input`}
                error={!!formState.errors.newPassword}
                helperText={t(formState.errors.newPassword?.message ?? '', {
                  characters: ALLOW_SPECIAL_CHARACTER,
                })}
                registrationForm={register('newPassword', {
                  onChange: event => {
                    const confirmNewPassword = getValues('confirmNewPassword');
                    if (event.target.value === confirmNewPassword) {
                      clearErrors('confirmNewPassword');
                    } else if (confirmNewPassword) {
                      trigger('confirmNewPassword');
                    }
                  },
                })}
              />
              <InputText
                id="confirm-new-password"
                label={t('auth.passwordRecover.reset.input.confirmNewPassword')}
                placeholder={t(
                  'auth.passwordRecover.reset.input.confirmNewPassword',
                )}
                type="password"
                fullWidth
                error={!!formState.errors.confirmNewPassword}
                helperText={t(
                  formState.errors.confirmNewPassword?.message ?? '',
                  { characters: ALLOW_SPECIAL_CHARACTER },
                )}
                registrationForm={register('confirmNewPassword')}
                className={`${classes.resetForm}__input`}
              />
              <Button
                variant="contained"
                size="medium"
                type="submit"
                disabled={Object.keys(formState.errors).length > 0}
              >
                {t('auth.login.button.login')}
              </Button>
            </>
          )}
        </Form>
      )}
    </Paper>
  );
};
