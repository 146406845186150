import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar as MuiAppBar,
  Avatar,
  Box,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Paper,
  Popover,
  MenuList,
  MenuItem,
  Typography,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TakachioLogo from '@/assets/takachio_logo_color.png';
import { useAuth } from '@/providers/auth';

const useStyles = makeStyles(() =>
  createStyles({
    appBarRoot: {
      boxShadow: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      height: 'auto',
      '&__logo': {
        maxWidth: '200px',
        maxHeight: '200px',
      },
      zIndex: '999',
    },
    userButton: {
      '&__profile': {
        padding: '16px',
        '&__name': {
          color: '#686868',
        },
      },
    },
  }),
);

const UserButton = () => {
  const { t } = useTranslation();
  const { authUser, logoutFn } = useAuth();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'account-popover' : undefined;
  const userNavigation = [
    {
      name: t('auth.logOut'),
      onClick: () => logoutFn(),
    },
  ];

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        disableFocusRipple={true}
        onClick={handleOpenMenu}
        aria-label="open user setting"
      >
        <Avatar alt="user avatar" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper>
          <Grid
            item
            container
            direction="column"
            className={`${classes.userButton}__profile`}
          >
            <Typography variant="body2" onClick={handleCloseMenu}>
              {t('component.appBar.account')}
            </Typography>
            <Typography
              variant="body2"
              className={`${classes.userButton}__profile__name`}
            >
              {authUser?.name}
            </Typography>
          </Grid>
          <Divider />
          <MenuList>
            {userNavigation.map(({ name, onClick }) => (
              <MenuItem key={name} onClick={onClick}>
                <Typography variant="body2" component="div">
                  {name}
                </Typography>
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Popover>
    </>
  );
};

export type AppBarProps = {
  /**
   * Set state function for sidebar.
   */
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppBar: React.FC<AppBarProps> = ({
  setSidebarOpen,
}: AppBarProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const onClickMenuIcon = () => {
    setSidebarOpen(prevState => !prevState);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MuiAppBar className={classes.appBarRoot} component="nav">
        <Toolbar>
          <Grid item container justifyContent="flex-start" alignItems="center">
            {!matchesSM && (
              <CardMedia
                component="img"
                image={TakachioLogo}
                alt="logo"
                className={`${classes.appBarRoot}__logo`}
              />
            )}
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open sidebar"
              onClick={onClickMenuIcon}
              sx={{ ml: matchesSM ? 0 : 2 }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item container justifyContent="flex-end" alignItems="center">
            <UserButton />
          </Grid>
        </Toolbar>
      </MuiAppBar>
    </Box>
  );
};
