import type { AxiosRequestConfig } from 'axios';

import { ImportCasesRequest, ImportcasesApi } from '@/bundles/model/';
import { ApiConfiguration } from '@/lib/swagger';

const importCasesAPI = new ImportcasesApi(new ApiConfiguration().configuration);

export const importCaseApi = async (
  importCaseRequest: ImportCasesRequest,
  options?: AxiosRequestConfig,
): Promise<void> => {
  const { cases, products, thefts, nowDate } = importCaseRequest;
  if (!cases || !products || !thefts || !nowDate) throw Error();

  await importCasesAPI.importCases(importCaseRequest, options);
};
