import type { AxiosRequestConfig } from 'axios';

import { QueryUserCompany } from '../types';

import {
  ListUserCompaniesResponse,
  ListusercompaniesApi,
} from '@/bundles/model';
import { ApiConfiguration } from '@/lib/swagger';

const listUserCompaniesAPI = new ListusercompaniesApi(
  new ApiConfiguration().configuration,
);

export const getUserCompanies = async (
  query: QueryUserCompany,
  options?: AxiosRequestConfig,
): Promise<ListUserCompaniesResponse> => {
  const response = await listUserCompaniesAPI.listUserCompanies(
    query.includeInProject,
    query.page,
    query.size,
    query.companyName,
    query.onlyActive,
    options,
  );

  if (response.status !== 200) {
    throw new Error('getUserCompanies failed!');
  }

  return response.data;
};
