import React, { useEffect } from 'react';
import {
  RouteObject,
  useLocation,
  useNavigate,
  useRoutes,
} from 'react-router-dom';

import { privateRoutes } from './private';
import { publicRoutes } from './public';
import { routes } from './routes';

import { IS_MAINTENANCE } from '@/config';
import {
  LINE_QUERY_STRING,
  LINE_REDIRECT_URL,
} from '@/features/line/constants';
import { Forbidden, NotFound, ErrorPage } from '@/features/misc';
import { useHit } from '@/hooks/useHit';
import { useOnMount } from '@/hooks/useOnMount';
import { useAuth } from '@/providers/auth';
import storage from '@/utils/localstorage';

const isMaintain = IS_MAINTENANCE === 'true';
let firstAcess = true;

export const AppRoutes = () => {
  const { authUser, justLogin } = useAuth();
  const { pathname, search } = useLocation();

  const navigate = useNavigate();

  //* Setting redirect for when access line message without authenticate
  useOnMount(() => {
    if (
      authUser ||
      pathname !== routes.line.index ||
      !search.includes(LINE_QUERY_STRING)
    ) {
      return;
    }

    const historyPath = `${pathname}${search}`;
    storage.set(LINE_REDIRECT_URL, historyPath);
  });

  const commonRoutes: RouteObject[] = [
    { path: routes.misc.notFound, element: <NotFound /> },
    { path: routes.misc.forbidden, element: <Forbidden /> },
  ];

  const appRoutes = authUser ? privateRoutes : publicRoutes;

  const element = useRoutes([...appRoutes, ...commonRoutes]);

  const { createHit } = useHit();

  useEffect(() => {
    if (isMaintain) navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!authUser) return;

    if (firstAcess && !justLogin) {
      firstAcess = false;
      let retryCount = 1;

      const fetchData = async () => {
        const res = await createHit();
        if (!res && retryCount < 3) {
          retryCount += 1;
          setTimeout(fetchData, 1000);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, justLogin]);

  if (isMaintain) {
    return (
      <React.Fragment>
        <ErrorPage />
      </React.Fragment>
    );
  }

  return <React.Fragment>{element}</React.Fragment>;
};
