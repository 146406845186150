import { useEffect } from 'react';

import { useDeepCompareMemoize } from './useDeepCompareMemoize';

type UseEffectParams = Parameters<typeof useEffect>;
type EffectCallback = UseEffectParams[0];
type DependencyList = UseEffectParams[1];

export const useDebouncedEffect = (
  effect: EffectCallback,
  deps: DependencyList,
  delay: number = 0,
) => {
  const dependencies: DependencyList = [deps, delay];

  useEffect(() => {
    const handler = setTimeout(() => {
      effect();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDeepCompareMemoize(dependencies)]);
};
