import type { AxiosRequestConfig } from 'axios';

import { CaseSearchRequest } from '../types';

import { ListcasesApi, ListCasesResponse } from '@/bundles/model/';
import { ApiConfiguration } from '@/lib/swagger';

const getCasesAPI = new ListcasesApi(new ApiConfiguration().configuration);

export const getCases = async (
  searchRequest: CaseSearchRequest,
  options?: AxiosRequestConfig,
): Promise<ListCasesResponse> => {
  const {
    companyId,
    storeName,
    industryIds,
    prefectures,
    municipalities,
    occurrenceStartDate,
    occurrenceEndDate,
    dayOfWeek,
    caseId,
    caseType,
    totalPriceFrom,
    totalPriceTo,
    vehicleNumber,
    situation,
    numberOfPeople,
    gender,
    age,
    nationality,
    janCode,
    productName,
    freeComment,
    emlinxStatus,
    page,
    pageSize,
    sortBy,
    sortOrder,
  } = searchRequest;

  const response = await getCasesAPI.getCases(
    companyId,
    storeName,
    industryIds,
    prefectures,
    municipalities,
    occurrenceStartDate,
    occurrenceEndDate,
    dayOfWeek,
    caseId,
    caseType,
    totalPriceFrom,
    totalPriceTo,
    vehicleNumber,
    situation,
    numberOfPeople,
    gender,
    age,
    nationality,
    janCode,
    productName,
    freeComment,
    emlinxStatus,
    page,
    pageSize,
    sortBy,
    sortOrder,
    options,
  );

  return response.data;
};
