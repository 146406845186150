import { Suspense } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { routes } from './routes';

import { MainLayout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';

const { ChangePassword } = lazyImport(
  () => import('@/features/auth'),
  'ChangePassword',
);

const { IndustryRoutes } = lazyImport(
  () => import('@/features/industry'),
  'IndustryRoutes',
);

const { CompaniesRoutes } = lazyImport(
  () => import('@/features/companies'),
  'CompaniesRoutes',
);

const { AdministratorsRoutes } = lazyImport(
  () => import('@/features/administrators'),
  'AdministratorsRoutes',
);

const { ProjectsRoutes } = lazyImport(
  () => import('@/features/projects'),
  'ProjectsRoutes',
);

const { DashboardRoutes } = lazyImport(
  () => import('@/features/dashboard'),
  'DashboardRoutes',
);

const { StoresRoutes } = lazyImport(
  () => import('@/features/stores'),
  'StoresRoutes',
);

const { CasesRoutes } = lazyImport(
  () => import('@/features/cases'),
  'CasesRoutes',
);

const { LoginInfoRoutes } = lazyImport(
  () => import('@/features/login-info'),
  'LoginInfoRoutes',
);

const { FaqRoutes } = lazyImport(() => import('@/features/faq'), 'FaqRoutes');

const { GuidelinesRoutes } = lazyImport(
  () => import('@/features/guidelines'),
  'GuidelinesRoutes',
);

const { LineRoutes } = lazyImport(
  () => import('@/features/line/'),
  'LineRoutes',
);

const { DocumentRoutes } = lazyImport(
  () => import('@/features/documents/'),
  'DocumentRoutes',
);

const { VideoRoutes } = lazyImport(
  () => import('@/features/videos/'),
  'VideoRoutes',
);

const privateRoutes: RouteObject[] = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<></>}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: routes.industry.wildcard, element: <IndustryRoutes /> },
      {
        path: routes.administrators.wildcard,
        element: <AdministratorsRoutes />,
      },
      { path: routes.projects.wildcard, element: <ProjectsRoutes /> },
      { path: routes.dashboard.wildcard, element: <DashboardRoutes /> },
      { path: routes.companies.wildcard, element: <CompaniesRoutes /> },
      { path: routes.stores.wildcard, element: <StoresRoutes /> },
      { path: routes.line.wildcard, element: <LineRoutes /> },
      { path: routes.cases.wildcard, element: <CasesRoutes /> },
      { path: routes.loginInfo.wildcard, element: <LoginInfoRoutes /> },
      { path: routes.auth.changePassword, element: <ChangePassword /> },
      { path: routes.faq.wildcard, element: <FaqRoutes /> },
      { path: routes.guidelines.wildcard, element: <GuidelinesRoutes /> },
      { path: routes.documents.wildcard, element: <DocumentRoutes /> },
      { path: routes.videos.wildcard, element: <VideoRoutes /> },
      {
        path: '/',
        element: (
          <Navigate to={`${routes.cases.index}${routes.cases.record.all}`} />
        ),
      },
      { path: '*', element: <Navigate to={routes.misc.notFound} /> },
    ],
  },
];

export { privateRoutes };
