import type { AxiosRequestConfig } from 'axios';

import { ForgotpasswordApi, ForgotPasswordRequest } from '@/bundles/model/';
import { ApiConfiguration } from '@/lib/swagger';

const forgotPasswordAPI = new ForgotpasswordApi(
  new ApiConfiguration().configuration,
);

export const forgotPassword = async (
  forgotPasswordRequest?: ForgotPasswordRequest,
  options?: AxiosRequestConfig,
): Promise<void> => {
  await forgotPasswordAPI.postForgotPassword(forgotPasswordRequest, options);
};
