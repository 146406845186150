import { Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100vh',
      '&__content': {
        '& > *:not(:first-child)': {
          paddingLeft: '16px',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      whiteSpace: 'pre-line',
    },
    space: {
      marginTop: '20px',
    },
  }),
);

export const ErrorPage = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.container}
      justifyContent="center"
      alignItems="center"
      direction="column"
      spacing={2}
    >
      <Grid
        item
        container
        justifyContent="center"
        className={`${classes.container}__content`}
      >
        <img src="/emlinx_logo.png" alt="" />
        <Typography variant="body2" className={classes.space}>
          現在、システムメンテナンスを実施中です。
        </Typography>
        <Typography variant="body2" className={classes.space}>
          ご不便をおかけして申し訳ございませんが、
        </Typography>
        <Typography variant="body2">
          より良いサービス提供のために以下の時間帯でメンテナンスを行っております：
        </Typography>
        <Typography variant="body2" className={classes.space}>
          メンテナンス日時：
        </Typography>
        <Typography variant="body2">開始日時: 2024年5月29日 12:30</Typography>
        <Typography variant="body2">
          終了予定日時: 2024年5月29日 16:30
        </Typography>
        <Typography variant="body2" className={classes.space}>
          この間、全てのサービスがご利用いただけません。
        </Typography>
        <Typography variant="body2" className={classes.space}>
          お客様にはご迷惑をおかけしますが、ご理解とご協力をお願い申し上げます。
        </Typography>
        <Typography variant="body2" className={classes.space}>
          お問い合わせ：
        </Typography>
        <Typography variant="body2">
          もしご質問やご不明な点がございましたら、以下の連絡先までお問い合わせください。
        </Typography>
        <Typography variant="body2">
          Email: emlinx-info@takachiho-kk.co.jp
        </Typography>
      </Grid>
    </Grid>
  );
};
