import {
  CardMedia,
  Divider,
  Drawer,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

import { APP_BAR_HEIGHT, SIDEBAR_WIDTH } from './constants';
import { SidebarProps } from './types';
import UserNavigation from './UserNavigation';

import TakachioLogo from '@/assets/takachio_logo_color.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidebar: {
      marginTop: APP_BAR_HEIGHT,
      whiteSpace: 'nowrap',
      backgroundColor: '#FFFFFF',
      width: SIDEBAR_WIDTH,
      transition: 'width 500ms ease',
      '&--open': {
        width: SIDEBAR_WIDTH,
        transition: 'width 500ms ease',
      },
      '&--closed': {
        overflowX: 'hidden',
        [theme.breakpoints.up('sm')]: {
          transition: 'width 500ms ease',
        },
        width: '0',
      },
      '@media (max-width: 600px)': {
        marginTop: '0px',
      },
      zIndex: 999,
    },
    logo: {
      maxWidth: '200px',
      maxHeight: '200px',
      padding: '16px',
    },
  }),
);

export const SideBar: React.FC<SidebarProps> = ({
  open,
  setSidebarOpen,
}: SidebarProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Drawer
      anchor="left"
      variant={matchesSM ? 'temporary' : 'permanent'}
      open={open}
      classes={{
        paper: clsx(classes.sidebar, {
          [`${classes.sidebar}--open`]: open,
          [`${classes.sidebar}--closed`]: !open,
        }),
      }}
      onClose={() => setSidebarOpen(prevState => !prevState)}
    >
      {matchesSM && (
        <>
          <CardMedia
            component="img"
            image={TakachioLogo}
            alt="logo"
            style={{ maxWidth: '200px', maxHeight: '200px', padding: '16px' }}
          />
          <Divider />
        </>
      )}
      <UserNavigation open={open} />
    </Drawer>
  );
};
