import { PickerLocale } from 'antd/es/date-picker/generatePicker';

/**
 * https://ant.design/components/date-picker#localization
 * Localization for Japanese (jaJP) language
 */
export const jaJP: PickerLocale = {
  lang: {
    locale: 'jaJP',
    placeholder: '日付を選択',
    rangePlaceholder: ['開始日', '終了日'],
    today: '今日',
    now: '現在',
    backToToday: '今日に戻る',
    ok: 'OK',
    clear: 'クリア',
    month: '月',
    year: '年',
    timeSelect: '時間を選択',
    dateSelect: '日付を選択',
    monthSelect: '月を選択',
    yearSelect: '年を選択',
    decadeSelect: '年代を選択',
    yearFormat: 'YYYY',
    dateFormat: 'YYYY/M/D',
    dayFormat: 'D',
    dateTimeFormat: 'YYYY/M/D HH:mm:ss',
    monthFormat: 'M月',
    monthBeforeYear: true,
    previousMonth: '前の月 (PageUp)',
    nextMonth: '次の月 (PageDown)',
    previousYear: '前の年 (Control + 左)',
    nextYear: '次の年 (Control + 右)',
    previousDecade: '前の年代',
    nextDecade: '次の年代',
    previousCentury: '前の世紀',
    nextCentury: '次の世紀',
    shortWeekDays: ['日', '月', '火', '水', '木', '金', '土'],
    shortMonths: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ],
  },
  timePickerLocale: {
    placeholder: '時間を選択',
  },
  dateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  weekFormat: 'YYYY-wo',
  monthFormat: 'YYYY-MM',
};
