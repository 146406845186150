import {
  LoginValues,
  ResetPasswordValues,
  ChangePasswordValues,
} from '../types';

const fakeLogin = async ({
  email,
  password,
}: LoginValues): Promise<{ jwt: string; expiresIn: number }> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const isValidCredentials =
        email === 'test@gmail.com' && password === '123';

      if (isValidCredentials) {
        return resolve({
          jwt: 'mock-token',
          expiresIn: 360000,
        });
      }

      reject(new Error());
    }, 500);
  });
};

const fakeValidateToken = async (
  token: string | null | undefined,
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (!token || token !== 'mock-token') {
        reject(false);
      } else {
        resolve(true);
      }
    }, 3000);
  });
};

const fakeResetPassword = async ({
  newPassword,
  confirmNewPassword,
}: ResetPasswordValues) => {
  if (newPassword !== confirmNewPassword) {
    return Promise.reject(
      new Error('New password and confirm new password do not match'),
    );
  }

  const passwordLength = /^.{8,64}$/.test(newPassword);
  const hasUpperCaseAndNumber = /^(?=.*[A-Z])(?=.*\d).*$/.test(newPassword);
  const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);

  if (!passwordLength || !hasUpperCaseAndNumber || !hasSpecialCharacter) {
    return Promise.reject(
      new Error('Password does not meet complexity requirements'),
    );
  }

  return Promise.resolve(true);
};

const fakeChangePassword = async ({
  currentPassword,
  newPassword,
  confirmNewPassword,
}: ChangePasswordValues) => {
  if (currentPassword === newPassword) {
    return Promise.reject(
      new Error('Current password and new password must be different'),
    );
  }

  return fakeResetPassword({ newPassword, confirmNewPassword })
    .then(() => Promise.resolve(true))
    .catch(error =>
      Promise.reject(new Error('Error changing password: ' + error.message)),
    );
};

export { fakeLogin, fakeValidateToken, fakeResetPassword, fakeChangePassword };
