/* tslint:disable */
/* eslint-disable */
/**
 * EMLINX API specification
 * This is API specification for EMLINX project.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { ListUserStoresResponse } from '../models';
/**
 * ListuserstoresApi - axios parameter creator
 * @export
 */
export const ListuserstoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List user stores
         * @summary List user stores
         * @param {number} [companyId] company id
         * @param {string} [storeName] Name of Store
         * @param {boolean} [includeInProject] Include store in same project with login user by
         * @param {number} [page] The page number to retrieve
         * @param {number} [size] The number of items per page
         * @param {boolean} [onlyActive] if true then only active companies returned else both active and inactive companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserStores: async (companyId?: number, storeName?: string, includeInProject?: boolean, page?: number, size?: number, onlyActive?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (storeName !== undefined) {
                localVarQueryParameter['storeName'] = storeName;
            }

            if (includeInProject !== undefined) {
                localVarQueryParameter['includeInProject'] = includeInProject;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (onlyActive !== undefined) {
                localVarQueryParameter['onlyActive'] = onlyActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListuserstoresApi - functional programming interface
 * @export
 */
export const ListuserstoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListuserstoresApiAxiosParamCreator(configuration)
    return {
        /**
         * List user stores
         * @summary List user stores
         * @param {number} [companyId] company id
         * @param {string} [storeName] Name of Store
         * @param {boolean} [includeInProject] Include store in same project with login user by
         * @param {number} [page] The page number to retrieve
         * @param {number} [size] The number of items per page
         * @param {boolean} [onlyActive] if true then only active companies returned else both active and inactive companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserStores(companyId?: number, storeName?: string, includeInProject?: boolean, page?: number, size?: number, onlyActive?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUserStoresResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserStores(companyId, storeName, includeInProject, page, size, onlyActive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ListuserstoresApi.listUserStores']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ListuserstoresApi - factory interface
 * @export
 */
export const ListuserstoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListuserstoresApiFp(configuration)
    return {
        /**
         * List user stores
         * @summary List user stores
         * @param {number} [companyId] company id
         * @param {string} [storeName] Name of Store
         * @param {boolean} [includeInProject] Include store in same project with login user by
         * @param {number} [page] The page number to retrieve
         * @param {number} [size] The number of items per page
         * @param {boolean} [onlyActive] if true then only active companies returned else both active and inactive companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserStores(companyId?: number, storeName?: string, includeInProject?: boolean, page?: number, size?: number, onlyActive?: boolean, options?: any): AxiosPromise<ListUserStoresResponse> {
            return localVarFp.listUserStores(companyId, storeName, includeInProject, page, size, onlyActive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListuserstoresApi - object-oriented interface
 * @export
 * @class ListuserstoresApi
 * @extends {BaseAPI}
 */
export class ListuserstoresApi extends BaseAPI {
    /**
     * List user stores
     * @summary List user stores
     * @param {number} [companyId] company id
     * @param {string} [storeName] Name of Store
     * @param {boolean} [includeInProject] Include store in same project with login user by
     * @param {number} [page] The page number to retrieve
     * @param {number} [size] The number of items per page
     * @param {boolean} [onlyActive] if true then only active companies returned else both active and inactive companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListuserstoresApi
     */
    public listUserStores(companyId?: number, storeName?: string, includeInProject?: boolean, page?: number, size?: number, onlyActive?: boolean, options?: RawAxiosRequestConfig) {
        return ListuserstoresApiFp(this.configuration).listUserStores(companyId, storeName, includeInProject, page, size, onlyActive, options).then((request) => request(this.axios, this.basePath));
    }
}

