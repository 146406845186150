/* tslint:disable */
/* eslint-disable */
/**
 * EMLINX API specification
 * This is API specification for EMLINX project.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { ListUserCompaniesResponse } from '../models';
/**
 * ListusercompaniesApi - axios parameter creator
 * @export
 */
export const ListusercompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List user companies
         * @summary List user companies
         * @param {boolean} [includeInProject] Include companies in same project with login user
         * @param {number} [page] The page number to retrieve
         * @param {number} [size] The number of items per page
         * @param {string} [name] company name
         * @param {boolean} [onlyActive] if true then only active companies returned else both active and inactive companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserCompanies: async (includeInProject?: boolean, page?: number, size?: number, name?: string, onlyActive?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeInProject !== undefined) {
                localVarQueryParameter['includeInProject'] = includeInProject;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (onlyActive !== undefined) {
                localVarQueryParameter['onlyActive'] = onlyActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListusercompaniesApi - functional programming interface
 * @export
 */
export const ListusercompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListusercompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * List user companies
         * @summary List user companies
         * @param {boolean} [includeInProject] Include companies in same project with login user
         * @param {number} [page] The page number to retrieve
         * @param {number} [size] The number of items per page
         * @param {string} [name] company name
         * @param {boolean} [onlyActive] if true then only active companies returned else both active and inactive companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserCompanies(includeInProject?: boolean, page?: number, size?: number, name?: string, onlyActive?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUserCompaniesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserCompanies(includeInProject, page, size, name, onlyActive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ListusercompaniesApi.listUserCompanies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ListusercompaniesApi - factory interface
 * @export
 */
export const ListusercompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListusercompaniesApiFp(configuration)
    return {
        /**
         * List user companies
         * @summary List user companies
         * @param {boolean} [includeInProject] Include companies in same project with login user
         * @param {number} [page] The page number to retrieve
         * @param {number} [size] The number of items per page
         * @param {string} [name] company name
         * @param {boolean} [onlyActive] if true then only active companies returned else both active and inactive companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserCompanies(includeInProject?: boolean, page?: number, size?: number, name?: string, onlyActive?: boolean, options?: any): AxiosPromise<ListUserCompaniesResponse> {
            return localVarFp.listUserCompanies(includeInProject, page, size, name, onlyActive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListusercompaniesApi - object-oriented interface
 * @export
 * @class ListusercompaniesApi
 * @extends {BaseAPI}
 */
export class ListusercompaniesApi extends BaseAPI {
    /**
     * List user companies
     * @summary List user companies
     * @param {boolean} [includeInProject] Include companies in same project with login user
     * @param {number} [page] The page number to retrieve
     * @param {number} [size] The number of items per page
     * @param {string} [name] company name
     * @param {boolean} [onlyActive] if true then only active companies returned else both active and inactive companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListusercompaniesApi
     */
    public listUserCompanies(includeInProject?: boolean, page?: number, size?: number, name?: string, onlyActive?: boolean, options?: RawAxiosRequestConfig) {
        return ListusercompaniesApiFp(this.configuration).listUserCompanies(includeInProject, page, size, name, onlyActive, options).then((request) => request(this.axios, this.basePath));
    }
}

