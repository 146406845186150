import type { AxiosRequestConfig } from 'axios';

import { GetuserApi, GetUserResponse } from '@/bundles/model/';
import { ApiConfiguration } from '@/lib/swagger';

const getUserAPI = new GetuserApi(new ApiConfiguration().configuration);

export const getAuthUser = async (
  id?: number,
  options?: AxiosRequestConfig,
): Promise<GetUserResponse> => {
  if (!id || isNaN(id)) {
    throw new Error();
  }

  const response = await getUserAPI.getUser(id, options);
  return response.data;
};
