import type { AxiosRequestConfig } from 'axios';

import { ListpoliciesApi, ListPoliciesResponse } from '@/bundles/model/';
import { ApiConfiguration } from '@/lib/swagger';

const getPoliciesAPI = new ListpoliciesApi(
  new ApiConfiguration().configuration,
);

export const getPolicies = async (
  options?: AxiosRequestConfig,
): Promise<ListPoliciesResponse> => {
  const response = await getPoliciesAPI.listPolicies(options);
  return response.data;
};
