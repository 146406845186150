import { Suspense } from 'react';
import { Outlet, RouteObject, Navigate } from 'react-router-dom';

import { routes } from './routes';

import { AuthLayout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';

const { Login } = lazyImport(() => import('@/features/auth'), 'Login');
const { ForgotPassword } = lazyImport(
  () => import('@/features/auth'),
  'ForgotPassword',
);

const publicRoutes: RouteObject[] = [
  {
    element: (
      <AuthLayout>
        <Suspense fallback={<></>}>
          <Outlet />
        </Suspense>
      </AuthLayout>
    ),
    children: [
      { path: routes.auth.login, element: <Login />, index: true },
      {
        path: `${routes.auth.forgotPassword.base}${routes.auth.forgotPassword.wildcard}`,
        element: <ForgotPassword />,
      },
      {
        path: '*',
        element: <Navigate to={routes.auth.login} />,
      },
    ],
  },
];

export { publicRoutes };
