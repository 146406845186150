import { Divider, Grid, Typography, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    forbidden: {
      height: '100vh',
      '&__content': {
        '& > *:not(:first-child)': {
          paddingLeft: '16px',
        },
      },
      whiteSpace: 'pre-line',
    },
  }),
);

export const Forbidden = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      className={classes.forbidden}
      justifyContent="center"
      alignItems="center"
      direction="column"
      spacing={2}
    >
      <Grid
        item
        container
        justifyContent="center"
        className={`${classes.forbidden}__content`}
      >
        <Typography variant="h1">{t('misc.forbidden.title')}</Typography>
        <Divider orientation="vertical" flexItem />
        <Typography variant="body2" alignSelf="center">
          {t('misc.forbidden.message')}
        </Typography>
      </Grid>

      <Grid item container justifyContent="center">
        <Button
          component={RouterLink}
          to="/"
          variant="text"
          size="large"
          replace={true}
        >
          {t('common.button.goToHomePage')}
        </Button>
      </Grid>
    </Grid>
  );
};
