import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import BookIcon from '@mui/icons-material/Book';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ErrorIcon from '@mui/icons-material/Error';
import FactoryIcon from '@mui/icons-material/Factory';
import GroupsIcon from '@mui/icons-material/Groups';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import StorageIcon from '@mui/icons-material/Storage';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import ViewListIcon from '@mui/icons-material/ViewList';

import { MenuItemProps } from './types';

const SELECTED_ITEM_BACKGROUND = '#0C8CE9';

const SIDEBAR_WIDTH = 270;
const APP_BAR_HEIGHT = 57;

const userNavigation: MenuItemProps[] = [
  {
    name: 'loginInfo.titleSidebar',
    icon: <StorageIcon />,
    path: '/login-info',
    accessEntity: 'menu:logininfo',
  },
  {
    name: 'dashboard.title',
    icon: <AnalyticsIcon />,
    path: '/dashboard',
    accessEntity: 'menu:dashboard',
  },
  {
    name: 'caseManagement.title',
    icon: <ErrorIcon />,
    children: [
      {
        name: 'caseManagement.menu.all',
        icon: <ViewListIcon />,
        path: ['/cases/all', '/cases/import'],
        accessEntity: 'menu:casesall',
      },
      {
        name: 'caseManagement.menu.approvalPending',
        icon: <HourglassBottomIcon />,
        path: '/cases/approval-pending',
        accessEntity: 'menu:casesapprovalpending',
      },
      {
        name: 'caseManagement.menu.editing',
        icon: <AssignmentLateIcon />,
        path: '/cases/editing',
        accessEntity: 'menu:casesediting',
      },
    ],
    accessEntity: 'menu:cases',
  },
  {
    name: 'accountManagement.title',
    icon: <ManageAccountsIcon />,
    children: [
      {
        name: 'accountManagement.administratorsManagement.title',
        icon: <PersonOutlineOutlinedIcon />,
        path: '/administrators',
        accessEntity: 'menu:administrators',
      },
      {
        name: 'accountManagement.companyManagement.list.title',
        icon: <CorporateFareIcon />,
        path: '/companies',
        accessEntity: 'menu:companies',
      },
      {
        name: 'accountManagement.storesManagement.title',
        icon: <StoreMallDirectoryOutlinedIcon />,
        path: '/stores',
        accessEntity: 'menu:stores',
      },
    ],
    accessEntity: 'menu:accounts',
  },
  {
    name: 'industryManagement.title',
    icon: <FactoryIcon />,
    path: '/industry',
    accessEntity: 'menu:industries',
  },
  {
    name: 'projectManagement.name',
    icon: <GroupsIcon />,
    path: '/projects',
    accessEntity: 'menu:projects',
  },
  {
    name: 'faq.title',
    icon: <QuestionAnswerIcon />,
    path: '/faq',
    accessEntity: 'menu:faqs',
  },
  {
    name: 'guidelines.name',
    icon: <BookIcon />,
    path: '/guidelines',
    accessEntity: 'menu:guidelines',
  },
];

const menuLinks = [
  {
    key: 'homepage',
    url: 'https://www.takachiho-kk.co.jp/prod/store_security/emlinx/',
  },
  {
    key: 'emlinxList',
    url: 'https://www.takachiho-kk.co.jp/prod/store_security/subscribers/',
  },
];

export {
  SELECTED_ITEM_BACKGROUND,
  SIDEBAR_WIDTH,
  userNavigation,
  menuLinks,
  APP_BAR_HEIGHT,
};
