/* eslint-disable indent */
import { useState, useCallback, Dispatch, SetStateAction } from 'react';

import { useUpdateEffect } from './useUpdateEffect';

import storage from '@/utils/localstorage';

type UseLocalStorageOptions = {
  /**
   * If `true` (default), the hook will initialize reading the local storage
   */
  initializeWithValue?: boolean;
};

export const useLocalStorage = <T>(
  key: string,
  initialValue: T | (() => T),
  options: UseLocalStorageOptions = {},
) => {
  const { initializeWithValue = true } = options;

  // Parse value with given generic type
  const deserializer = useCallback<(value: string) => T>(
    value => {
      // Check 'undefined' as undefined
      if (value === undefined || value === 'undefined') {
        return undefined as unknown as T;
      }

      const defaultValue =
        initialValue instanceof Function ? initialValue() : initialValue;

      let parsed: unknown;
      try {
        parsed = JSON.parse(value);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        return defaultValue;
      }

      return parsed as T;
    },
    [initialValue],
  );

  const readValue = useCallback((): T => {
    const valueFromStorage = storage.get(key);
    if (valueFromStorage) return deserializer(valueFromStorage);
    return initialValue instanceof Function ? initialValue() : initialValue;
  }, [deserializer, initialValue, key]);

  const [storedValue, setStoredValue] = useState(() => {
    if (initializeWithValue) {
      return readValue();
    }

    return initialValue instanceof Function ? initialValue() : initialValue;
  });

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    value => {
      try {
        // Allow value to be a function so we have the same API as useState
        const newValue = value instanceof Function ? value(readValue()) : value;

        // Save to local storage
        setStoredValue(newValue);

        // Save state
        storage.set(key, JSON.stringify(newValue));

        // Dispatch a custom event so every similar useLocalStorage hook is notified
        window.dispatchEvent(new StorageEvent('local-storage', { key }));
      } catch (error) {
        console.warn(`Error reading localStorage key “${key}”:`, error);
      }
    },
    [key, readValue],
  );

  const removeValue = useCallback(() => {
    const defaultValue =
      initialValue instanceof Function ? initialValue() : initialValue;

    // Remove the key from local storage
    storage.clear(key);

    // Reset state with default value
    setStoredValue(defaultValue);

    // Dispatch a custom event so every similar useLocalStorage hook is notified
    window.dispatchEvent(new StorageEvent('local-storage', { key }));
  }, [initialValue, key]);

  useUpdateEffect(() => {
    setStoredValue(readValue());
  }, [key]);

  return { storedValue, setValue, removeValue };
};
