import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WestIcon from '@mui/icons-material/West';
import {
  Button,
  Divider,
  InputAdornment,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { forgotPassword } from '../api/forgotPassword';
import { ForgotPasswordValues } from '../types';

import { Form } from '@/components/Form';
import { InputText } from '@/components/InputText';
import { routes } from '@/routes';
import { EmailRegex } from '@/utils/constant';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: '20px',
      maxWidth: '400px',
    },
    requestForm: {
      display: 'flex',
      flexDirection: 'column',
      '& > *:not(:first-child)': {
        marginTop: '16px',
      },
      '&__title': {
        fontWeight: 'normal',
        textAlign: 'center',
      },
      '&__subtitle': {
        color: '#686868',
      },
      '&__link': {
        color: '#4695D9',
        width: 'fit-content',
      },
    },
    formInput: {
      '&__icon': {
        marginRight: '4px',
      },
    },
    returnButton: {
      marginTop: '16px',
    },
  }),
);

const formSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'auth.login.validate.emailIsRequired' })
    .refine(
      value => !value || value === '' || EmailRegex.test(value),
      { message:'auth.login.validate.emailNotValidFormat' },
    ),
});

type ForgotPasswordFormProps = {
  onSuccess: (email: string) => void;
};

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  onSuccess,
}: ForgotPasswordFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const submitRequest = async (requestVal: ForgotPasswordValues) => {
    try {
      await forgotPassword({
        email: requestVal.email,
      });
      onSuccess(requestVal.email);
    } catch (error) {
      console.error(error);
      toast.error(t('error.unexpectedError'));
    }
  };

  return (
    <Grid item>
      <Paper className={classes.paper} elevation={5}>
        <Form<ForgotPasswordValues, typeof formSchema>
          onSubmit={submitRequest}
          className={classes.requestForm}
          schema={formSchema}
          options={{
            mode: 'all',
          }}
        >
          {({ register, formState }) => (
            <>
              <Typography
                variant="h2"
                className={`${classes.requestForm}__title`}
              >
                {t('auth.passwordRecover.title.forgotPassword')}
              </Typography>
              <Grid className={`${classes.requestForm}__subtitle`}>
                <Typography variant="body2">
                  {t('auth.passwordRecover.request.inputEmailReminder')}
                </Typography>
                <Typography variant="body2">
                  {t('auth.passwordRecover.request.instructionAdvice')}
                </Typography>
              </Grid>
              <Divider className={`${classes.requestForm}__divider`} />
              <InputText
                id="email"
                label={t('auth.passwordRecover.request.input.email')}
                placeholder={t('auth.passwordRecover.request.input.email')}
                fullWidth
                className={`${classes.requestForm}__input`}
                error={!!formState.errors.email}
                helperText={t(formState.errors.email?.message || '')}
                registrationForm={register('email')}
                autoComplete="email"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailOutlineIcon
                        className={`${classes.formInput}__icon`}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                size="medium"
                type="submit"
                disabled={Object.keys(formState.errors).length > 0}
              >
                {t('auth.passwordRecover.request.button.transmission')}
              </Button>
            </>
          )}
        </Form>
      </Paper>
      <Button
        to={routes.auth.login}
        component={RouterLink}
        startIcon={<WestIcon />}
        className={classes.returnButton}
      >
        {t('common.button.returnPreviousScreen')}
      </Button>
    </Grid>
  );
};
