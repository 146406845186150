import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
  Button,
  Divider,
  InputAdornment,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { z } from 'zod';

import { LoginValues } from '../types';

import { Form } from '@/components/Form';
import { InputText } from '@/components/InputText';
import { useAuth } from '@/providers/auth';
import { routes } from '@/routes';
import { EmailRegex } from '@/utils/constant';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: '20px',
      maxWidth: '400px',
      width: '100%',
    },
    loginForm: {
      display: 'flex',
      flexDirection: 'column',
      '& > *:not(:first-child)': {
        marginTop: '16px',
      },
      '&__title': {
        fontWeight: 'normal',
        textAlign: 'center',
      },
      '&__link': {
        color: '#4695D9',
        width: 'fit-content',
      },
    },
    formInput: {
      '&__icon': {
        marginRight: '4px',
      },
    },
  }),
);

const ALLOW_SPECIAL_CHARACTER = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';

const loginSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'auth.login.validate.emailIsRequired' })
    .refine(
      value => !value || value === '' || EmailRegex.test(value),
      { message:'auth.login.validate.emailNotValidFormat' },
    ),
  password: z
    .string()
    .min(1, 'auth.passwordRecover.reset.validate.newPassword.isRequired')
    .regex(
      /\d/,
      'auth.passwordRecover.reset.validate.newPassword.shouldHaveNumber',
    )
    .regex(
      /[a-z]/,
      'auth.passwordRecover.reset.validate.newPassword.shouldHaveLowerLetter',
    )
    .regex(
      /[A-Z]/,
      'auth.passwordRecover.reset.validate.newPassword.shouldHaveUpperLetter',
    )
    .regex(
      /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+/,
      'auth.passwordRecover.reset.validate.newPassword.shouldHaveSpecialCharacter',
    )
    .refine(
      value => value.length >= 8 && value.length <= 64,
      'auth.passwordRecover.reset.validate.newPassword.validLength',
    ),
});

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm: React.FC<LoginFormProps> = ({
  onSuccess,
}: LoginFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { loginFn } = useAuth();

  const submitLogin = async (formValues: LoginValues) => {
    const loginSuccess = await loginFn(formValues);
    if (!loginSuccess) {
      return;
    }

    onSuccess();
  };

  return (
    <Paper className={classes.paper} elevation={5}>
      <Form<LoginValues, typeof loginSchema>
        onSubmit={submitLogin}
        className={classes.loginForm}
        schema={loginSchema}
        options={{
          mode: 'all',
        }}
      >
        {({ register, formState }) => (
          <>
            <Typography variant="h2" className={`${classes.loginForm}__title`}>
              {t('auth.login.title')}
            </Typography>
            <Divider className={`${classes.loginForm}__divider`} />
            <InputText
              id="email"
              label={t('auth.login.input.email')}
              placeholder={t('auth.login.input.email')}
              fullWidth
              className={`${classes.loginForm}__input`}
              error={!!formState.errors.email}
              helperText={t(formState.errors['email']?.message || '')}
              registrationForm={register('email')}
              autoComplete="email"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonOutlineIcon
                      className={`${classes.formInput}__icon`}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <InputText
              id="password"
              label={t('auth.login.input.password')}
              placeholder={t('auth.login.input.password')}
              type="password"
              fullWidth
              className={`${classes.loginForm}__input`}
              error={!!formState.errors.password}
              helperText={t(formState.errors.password?.message ?? '', {
                characters: ALLOW_SPECIAL_CHARACTER,
              })}
              registrationForm={register('password')}
            />

            <RouterLink
              to={`${routes.auth.forgotPassword.base}${routes.auth.forgotPassword.request}`}
              className={`${classes.loginForm}__link`}
            >
              {t('auth.login.button.forgotPassword')}
            </RouterLink>
            <Button
              variant="contained"
              size="medium"
              type="submit"
              disabled={
                Object.keys(formState.errors).length > 0 ||
                formState.isSubmitting
              }
            >
              {t('auth.login.button.login')}
            </Button>
          </>
        )}
      </Form>
    </Paper>
  );
};
