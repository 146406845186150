import { createHitAPI } from '@/routes/app';

export const useHit = () => {
  const createHit = async (): Promise<boolean | undefined> => {
    try {
      await createHitAPI();
      return true;
    } catch (error) {
      return false;
    }
  };

  return {
    createHit,
  };
};
