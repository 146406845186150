import axios from 'axios';

import { HttpResponseStatusCodes } from '../constant/httpResponseStatusCodes';

const isAxiosErrorStatus = (error: unknown, status: number): boolean => {
  return (
    (axios.isAxiosError(error) &&
      error.response &&
      error.response.status === status) ||
    false
  );
};

const is401Error = (error: unknown): boolean => {
  return isAxiosErrorStatus(error, HttpResponseStatusCodes.UNAUTHORIZED);
};

const is400Error = (error: unknown): boolean => {
  return isAxiosErrorStatus(error, HttpResponseStatusCodes.BAD_REQUEST);
};

const is403Error = (error: unknown): boolean => {
  return isAxiosErrorStatus(error, HttpResponseStatusCodes.FORBIDDEN);
};

export { is400Error, is401Error, is403Error };
