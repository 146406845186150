/* tslint:disable */
/* eslint-disable */
/**
 * EMLINX API specification
 * This is API specification for EMLINX project.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { ListCasesResponse } from '../models';
/**
 * ListcasesApi - axios parameter creator
 * @export
 */
export const ListcasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List Cases
         * @summary GET Cases
         * @param {number} [companyId] Company ID
         * @param {string} [storeName] Name of the store
         * @param {Array<number>} [industryIds] List of industry IDs
         * @param {Array<string>} [prefectures] List of prefectures
         * @param {Array<string>} [municipalities] List of municipalities
         * @param {string} [occurrenceStartDate] Start date of the occurrence
         * @param {string} [occurrenceEndDate] End date of the occurrence
         * @param {Array<number>} [dayOfWeek] List of days of the week
         * @param {string} [caseId] ID of the case
         * @param {Array<number>} [caseType] List of case types
         * @param {number} [totalPriceFrom] Total price from
         * @param {number} [totalPriceTo] Total price to
         * @param {string} [vehicleNumber] Vehicle number
         * @param {Array<number>} [situation] List of situations
         * @param {number} [numberOfPeople] Number of people
         * @param {number} [gender] Gender
         * @param {number} [age] Age
         * @param {number} [nationality] Nationality
         * @param {string} [janCode] JAN code
         * @param {string} [productName] Product name
         * @param {string} [freeComment] Free comment
         * @param {string} [emlinxStatus] Emlinx status
         * @param {number} [page] The page number to retrieve
         * @param {number} [size] The number of items per page
         * @param {Array<GetCasesSortByEnum>} [sortBy] Specifies the attribute to sort by.
         * @param {Array<GetCasesSortOrderEnum>} [sortOrder] Specifies the order of sorting, either ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCases: async (companyId?: number, storeName?: string, industryIds?: Array<number>, prefectures?: Array<string>, municipalities?: Array<string>, occurrenceStartDate?: string, occurrenceEndDate?: string, dayOfWeek?: Array<number>, caseId?: string, caseType?: Array<number>, totalPriceFrom?: number, totalPriceTo?: number, vehicleNumber?: string, situation?: Array<number>, numberOfPeople?: number, gender?: number, age?: number, nationality?: number, janCode?: string, productName?: string, freeComment?: string, emlinxStatus?: string, page?: number, size?: number, sortBy?: Array<GetCasesSortByEnum>, sortOrder?: Array<GetCasesSortOrderEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (storeName !== undefined) {
                localVarQueryParameter['storeName'] = storeName;
            }

            if (industryIds) {
                localVarQueryParameter['industryIds'] = industryIds;
            }

            if (prefectures) {
                localVarQueryParameter['prefectures'] = prefectures;
            }

            if (municipalities) {
                localVarQueryParameter['municipalities'] = municipalities;
            }

            if (occurrenceStartDate !== undefined) {
                localVarQueryParameter['occurrenceStartDate'] = occurrenceStartDate;
            }

            if (occurrenceEndDate !== undefined) {
                localVarQueryParameter['occurrenceEndDate'] = occurrenceEndDate;
            }

            if (dayOfWeek) {
                localVarQueryParameter['dayOfWeek'] = dayOfWeek;
            }

            if (caseId !== undefined) {
                localVarQueryParameter['caseId'] = caseId;
            }

            if (caseType) {
                localVarQueryParameter['caseType'] = caseType;
            }

            if (totalPriceFrom !== undefined) {
                localVarQueryParameter['totalPriceFrom'] = totalPriceFrom;
            }

            if (totalPriceTo !== undefined) {
                localVarQueryParameter['totalPriceTo'] = totalPriceTo;
            }

            if (vehicleNumber !== undefined) {
                localVarQueryParameter['vehicleNumber'] = vehicleNumber;
            }

            if (situation) {
                localVarQueryParameter['situation'] = situation;
            }

            if (numberOfPeople !== undefined) {
                localVarQueryParameter['numberOfPeople'] = numberOfPeople;
            }

            if (gender !== undefined) {
                localVarQueryParameter['gender'] = gender;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }

            if (nationality !== undefined) {
                localVarQueryParameter['nationality'] = nationality;
            }

            if (janCode !== undefined) {
                localVarQueryParameter['janCode'] = janCode;
            }

            if (productName !== undefined) {
                localVarQueryParameter['productName'] = productName;
            }

            if (freeComment !== undefined) {
                localVarQueryParameter['freeComment'] = freeComment;
            }

            if (emlinxStatus !== undefined) {
                localVarQueryParameter['emlinxStatus'] = emlinxStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListcasesApi - functional programming interface
 * @export
 */
export const ListcasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListcasesApiAxiosParamCreator(configuration)
    return {
        /**
         * List Cases
         * @summary GET Cases
         * @param {number} [companyId] Company ID
         * @param {string} [storeName] Name of the store
         * @param {Array<number>} [industryIds] List of industry IDs
         * @param {Array<string>} [prefectures] List of prefectures
         * @param {Array<string>} [municipalities] List of municipalities
         * @param {string} [occurrenceStartDate] Start date of the occurrence
         * @param {string} [occurrenceEndDate] End date of the occurrence
         * @param {Array<number>} [dayOfWeek] List of days of the week
         * @param {string} [caseId] ID of the case
         * @param {Array<number>} [caseType] List of case types
         * @param {number} [totalPriceFrom] Total price from
         * @param {number} [totalPriceTo] Total price to
         * @param {string} [vehicleNumber] Vehicle number
         * @param {Array<number>} [situation] List of situations
         * @param {number} [numberOfPeople] Number of people
         * @param {number} [gender] Gender
         * @param {number} [age] Age
         * @param {number} [nationality] Nationality
         * @param {string} [janCode] JAN code
         * @param {string} [productName] Product name
         * @param {string} [freeComment] Free comment
         * @param {string} [emlinxStatus] Emlinx status
         * @param {number} [page] The page number to retrieve
         * @param {number} [size] The number of items per page
         * @param {Array<GetCasesSortByEnum>} [sortBy] Specifies the attribute to sort by.
         * @param {Array<GetCasesSortOrderEnum>} [sortOrder] Specifies the order of sorting, either ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCases(companyId?: number, storeName?: string, industryIds?: Array<number>, prefectures?: Array<string>, municipalities?: Array<string>, occurrenceStartDate?: string, occurrenceEndDate?: string, dayOfWeek?: Array<number>, caseId?: string, caseType?: Array<number>, totalPriceFrom?: number, totalPriceTo?: number, vehicleNumber?: string, situation?: Array<number>, numberOfPeople?: number, gender?: number, age?: number, nationality?: number, janCode?: string, productName?: string, freeComment?: string, emlinxStatus?: string, page?: number, size?: number, sortBy?: Array<GetCasesSortByEnum>, sortOrder?: Array<GetCasesSortOrderEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCasesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCases(companyId, storeName, industryIds, prefectures, municipalities, occurrenceStartDate, occurrenceEndDate, dayOfWeek, caseId, caseType, totalPriceFrom, totalPriceTo, vehicleNumber, situation, numberOfPeople, gender, age, nationality, janCode, productName, freeComment, emlinxStatus, page, size, sortBy, sortOrder, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ListcasesApi.getCases']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ListcasesApi - factory interface
 * @export
 */
export const ListcasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListcasesApiFp(configuration)
    return {
        /**
         * List Cases
         * @summary GET Cases
         * @param {number} [companyId] Company ID
         * @param {string} [storeName] Name of the store
         * @param {Array<number>} [industryIds] List of industry IDs
         * @param {Array<string>} [prefectures] List of prefectures
         * @param {Array<string>} [municipalities] List of municipalities
         * @param {string} [occurrenceStartDate] Start date of the occurrence
         * @param {string} [occurrenceEndDate] End date of the occurrence
         * @param {Array<number>} [dayOfWeek] List of days of the week
         * @param {string} [caseId] ID of the case
         * @param {Array<number>} [caseType] List of case types
         * @param {number} [totalPriceFrom] Total price from
         * @param {number} [totalPriceTo] Total price to
         * @param {string} [vehicleNumber] Vehicle number
         * @param {Array<number>} [situation] List of situations
         * @param {number} [numberOfPeople] Number of people
         * @param {number} [gender] Gender
         * @param {number} [age] Age
         * @param {number} [nationality] Nationality
         * @param {string} [janCode] JAN code
         * @param {string} [productName] Product name
         * @param {string} [freeComment] Free comment
         * @param {string} [emlinxStatus] Emlinx status
         * @param {number} [page] The page number to retrieve
         * @param {number} [size] The number of items per page
         * @param {Array<GetCasesSortByEnum>} [sortBy] Specifies the attribute to sort by.
         * @param {Array<GetCasesSortOrderEnum>} [sortOrder] Specifies the order of sorting, either ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCases(companyId?: number, storeName?: string, industryIds?: Array<number>, prefectures?: Array<string>, municipalities?: Array<string>, occurrenceStartDate?: string, occurrenceEndDate?: string, dayOfWeek?: Array<number>, caseId?: string, caseType?: Array<number>, totalPriceFrom?: number, totalPriceTo?: number, vehicleNumber?: string, situation?: Array<number>, numberOfPeople?: number, gender?: number, age?: number, nationality?: number, janCode?: string, productName?: string, freeComment?: string, emlinxStatus?: string, page?: number, size?: number, sortBy?: Array<GetCasesSortByEnum>, sortOrder?: Array<GetCasesSortOrderEnum>, options?: any): AxiosPromise<ListCasesResponse> {
            return localVarFp.getCases(companyId, storeName, industryIds, prefectures, municipalities, occurrenceStartDate, occurrenceEndDate, dayOfWeek, caseId, caseType, totalPriceFrom, totalPriceTo, vehicleNumber, situation, numberOfPeople, gender, age, nationality, janCode, productName, freeComment, emlinxStatus, page, size, sortBy, sortOrder, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListcasesApi - object-oriented interface
 * @export
 * @class ListcasesApi
 * @extends {BaseAPI}
 */
export class ListcasesApi extends BaseAPI {
    /**
     * List Cases
     * @summary GET Cases
     * @param {number} [companyId] Company ID
     * @param {string} [storeName] Name of the store
     * @param {Array<number>} [industryIds] List of industry IDs
     * @param {Array<string>} [prefectures] List of prefectures
     * @param {Array<string>} [municipalities] List of municipalities
     * @param {string} [occurrenceStartDate] Start date of the occurrence
     * @param {string} [occurrenceEndDate] End date of the occurrence
     * @param {Array<number>} [dayOfWeek] List of days of the week
     * @param {string} [caseId] ID of the case
     * @param {Array<number>} [caseType] List of case types
     * @param {number} [totalPriceFrom] Total price from
     * @param {number} [totalPriceTo] Total price to
     * @param {string} [vehicleNumber] Vehicle number
     * @param {Array<number>} [situation] List of situations
     * @param {number} [numberOfPeople] Number of people
     * @param {number} [gender] Gender
     * @param {number} [age] Age
     * @param {number} [nationality] Nationality
     * @param {string} [janCode] JAN code
     * @param {string} [productName] Product name
     * @param {string} [freeComment] Free comment
     * @param {string} [emlinxStatus] Emlinx status
     * @param {number} [page] The page number to retrieve
     * @param {number} [size] The number of items per page
     * @param {Array<GetCasesSortByEnum>} [sortBy] Specifies the attribute to sort by.
     * @param {Array<GetCasesSortOrderEnum>} [sortOrder] Specifies the order of sorting, either ascending or descending.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListcasesApi
     */
    public getCases(companyId?: number, storeName?: string, industryIds?: Array<number>, prefectures?: Array<string>, municipalities?: Array<string>, occurrenceStartDate?: string, occurrenceEndDate?: string, dayOfWeek?: Array<number>, caseId?: string, caseType?: Array<number>, totalPriceFrom?: number, totalPriceTo?: number, vehicleNumber?: string, situation?: Array<number>, numberOfPeople?: number, gender?: number, age?: number, nationality?: number, janCode?: string, productName?: string, freeComment?: string, emlinxStatus?: string, page?: number, size?: number, sortBy?: Array<GetCasesSortByEnum>, sortOrder?: Array<GetCasesSortOrderEnum>, options?: RawAxiosRequestConfig) {
        return ListcasesApiFp(this.configuration).getCases(companyId, storeName, industryIds, prefectures, municipalities, occurrenceStartDate, occurrenceEndDate, dayOfWeek, caseId, caseType, totalPriceFrom, totalPriceTo, vehicleNumber, situation, numberOfPeople, gender, age, nationality, janCode, productName, freeComment, emlinxStatus, page, size, sortBy, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetCasesSortByEnum = {
    Id: 'id',
    OccurrenceDate: 'occurrence_date',
    LastUpdate: 'last_update'
} as const;
export type GetCasesSortByEnum = typeof GetCasesSortByEnum[keyof typeof GetCasesSortByEnum];
/**
 * @export
 */
export const GetCasesSortOrderEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetCasesSortOrderEnum = typeof GetCasesSortOrderEnum[keyof typeof GetCasesSortOrderEnum];
